import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../../core/common/collapse-header";
import SearchInput from "../../../core/common/searchInput/searchInput";
import CompanyService from "../../../services/company";
import pkg from "lodash";
import { SorterResult } from "antd/es/table/interface";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TablePaginationConfig } from "antd/lib/table";
import { filterSortCompanies } from "../../../core/data/redux/companies/thunk";
import {
  setCompanyView,
  setEditingCompany,
  setRemoveCompany,
} from "../../../core/data/redux/companies/companies";
import AddCompanyModal from "../../../core/modals/companies/add_company_modal";
import { Select } from "antd";
import { setActiveModal } from "../../../core/data/redux/modals/modals";
import { displayFieldDatetimeValue } from "../../../utils/helper";

const { debounce } = pkg;

const PAGE_ROOT = "contact";

const ManageCompanies = () => {
  const dispatch = useDispatch<any>();

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const companiesData =
    useSelector((state: any) => state.company.companies?.list) || [];

  const companiesPaginationData =
    useSelector((state: any) => state.company.companies?.pagination) || {};

  const companiesDataLoading = useSelector(
    (state: any) => state.company.company?.loading
  );

  const [columns, setColumns] = useState<any[]>([]);
  const [filterColumns, setFilterColumns] = useState<any[]>([]);

  const [searchTerm, setSearchTerm] = useState<any>("");
  const [fieldSearchResults, setFieldSearchResults] = useState<{
    [key: string]: [];
  }>({});

  const [filterSearchChecked, setFilterSearchChecked] = useState<{
    [key: string]: {
      [term: string]: true;
    };
  }>({});

  const [filterOperator, setFilterOperator] = useState<string>("or");

  const [order, setOrder] = useState<{
    [field: string]: string | null;
  }>({
    company_id: "desc",
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
    total: 0,
  });

  const onFieldSearch = async (field: string, value: string) => {
    if (!value || !value.length) {
      setFieldSearchResults({});
      return;
    }

    if (value?.length >= 3) {
      const response = await CompanyService.searchFieldValues(field, value);

      if (!response) {
        throw new Error("Search Field Values Failed!");
      }

      setFieldSearchResults((prev) => ({
        ...(prev || {}),
        [field]: response,
      }));
    }
  };

  const onSearch = (value: string) => {
    if (!value || !value.length) {
      setSearchTerm(null);
      return;
    }

    if (value?.length >= 3) {
      setSearchTerm(value);
    }
  };

  const onFilterRecords = () => {
    debouncedFilterSort({
      filters: filterSearchChecked,
      operator: filterOperator,
      term: "",
      order,
      currentPage: 1,
    });
  };

  const onFilterItemHandler = (field: string, e: any) => {
    const isFilterItemChecked = e.target.checked;
    setFilterSearchChecked((prev: any) => {
      let fieldSelectedItems: {} = {
        ...(prev?.[field] || {}),
        [e.target.value]: isFilterItemChecked,
      };

      return {
        ...prev,
        [field]: fieldSelectedItems,
      };
    });
  };

  const handleRowClick = (company: any) => {
    if (company) {
      dispatch(
        setCompanyView({
          visitingCompanyData: company,
        })
      );
      dispatch(
        setActiveModal({
          modalId: "detail_company",
        })
      );
    }
  };

  const onPaginationHandler = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const onSorterHandler = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    if (Array.isArray(sorter)) {
      const result: {
        [key: string]: string | null;
      } = {};
      sorter.map((sortItem: SorterResult<any>) => {
        const currentOrder =
          order?.[sortItem.columnKey as string] === undefined
            ? "ascend"
            : order?.[sortItem.columnKey as string] === null
            ? null
            : order?.[sortItem.columnKey as string];
        result[sortItem.columnKey as string] =
          currentOrder === "ascend"
            ? "descend"
            : currentOrder === "descend"
            ? null
            : "ascend";
      });
      setOrder((_) => {
        return {
          ...result,
        };
      });
    } else {
      if (sorter.columnKey) {
        setOrder((_) => {
          const currentOrder =
            order?.[sorter.columnKey as string] === undefined
              ? "ascend"
              : order?.[sorter.columnKey as string] === null
              ? null
              : order?.[sorter.columnKey as string];
          return {
            [sorter.columnKey as string]:
              currentOrder === "ascend"
                ? "descend"
                : currentOrder === "descend"
                ? null
                : "ascend",
          };
        });
      }
    }
  };

  useEffect(() => {
    setPagination(companiesPaginationData);
  }, [JSON.stringify(companiesPaginationData)]);

  const debouncedFilterSort = useMemo(
    () =>
      debounce(
        (params: {
          filters?: any;
          operator?: string;
          term?: string;
          order?: any;
          currentPage?: number;
          pageSize?: number;
        }) => {
          dispatch(
            filterSortCompanies({
              filters: params.filters || filterSearchChecked,
              operator: params.operator || filterOperator,
              term: params.term || searchTerm,
              order: params.order || order,
              currentPage: params.currentPage || pagination.current,
              pageSize: params.pageSize || pagination.pageSize,
            })
          );
        },
        1000
      ),
    [dispatch]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedFilterSort.cancel();
    };
  }, []);

  useEffect(() => {
    debouncedFilterSort({
      filters: filterSearchChecked,
      operator: filterOperator,
      term: searchTerm,
      order: order,
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  }, [
    pagination.current,
    pagination.pageSize,
    searchTerm,
    JSON.stringify(order),
  ]);

  useEffect(() => {
    if (fieldsAdminData?.[PAGE_ROOT]?.["Company"]?.["main"]?.["data"]?.length) {
      const filters = fieldsAdminData?.[PAGE_ROOT]?.["Company"]?.["main"]?.[
        "data"
      ]?.filter(
        (fieldConfiguration: any) =>
          fieldConfiguration.is_filter &&
          fieldConfiguration.filter_type === "multi-select"
      );
      setFilterColumns(filters);
      const columnsData =
        fieldsAdminData?.[PAGE_ROOT]?.["Company"]?.["main"]?.["data"]
          ?.filter(
            (fieldConfiguration: any) => fieldConfiguration.is_show_manage_table
          )
          ?.map((item: any, index: number) => ({
            title: item.display_name,
            dataIndex: item.original_name,
            key: item.original_name,
            width: item.width ?? 150,
            align: "left",
            ellipsis: true,
            sorter: true,
            sortDirections: ["ascend", "descend", null],
            render: (text: string, record: any, index: number) => (
              <div
                className={`table-avatar d-flex ${
                  item.display_field_type === "email-with-icon" ||
                  item.display_field_type === "phone-with-icon"
                    ? "justify-content-center"
                    : "justify-content-start"
                } align-items-center`}
                key={index}
              >
                {record?.[item.original_name] ? (
                  item.display_field_type === "email-with-icon" ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="refresh-tooltip"
                          style={{ position: "fixed" }}
                        >
                          {record?.[item.original_name]}
                        </Tooltip>
                      }
                    >
                      <div className="mx-2">
                        <i className={"ti-email"}></i>
                      </div>
                    </OverlayTrigger>
                  ) : item.display_field_type === "phone-with-icon" ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="refresh-tooltip"
                          style={{ position: "fixed" }}
                        >
                          {record?.[item.original_name]}
                        </Tooltip>
                      }
                    >
                      <div className="mx-2">
                        <i className={"ti-headphone"}></i>
                      </div>
                    </OverlayTrigger>
                  ) : item.display_field_type === "datetime" ||
                    item.display_field_type === "date" ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="refresh-tooltip"
                          style={{ position: "fixed" }}
                        >
                          {displayFieldDatetimeValue(
                            record?.[item.original_name],
                            item.display_field_type
                          )}
                        </Tooltip>
                      }
                    >
                      <span
                        className={`profile-split cursor-pointer`}
                        style={{
                          cursor: "pointer",
                          wordBreak: "keep-all",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {displayFieldDatetimeValue(
                          record?.[item.original_name],
                          item.display_field_type
                        )}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="refresh-tooltip"
                          style={{ position: "fixed" }}
                        >
                          {record?.[item.original_name]}
                        </Tooltip>
                      }
                    >
                      <span
                        className={`profile-split cursor-pointer ${
                          item.original_name === "company_id"
                            ? "text-decoration-underline"
                            : ""
                        }`}
                        style={{
                          cursor: "pointer",
                          wordBreak: "keep-all",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        onClick={() =>
                          item.original_name === "company_id"
                            ? handleRowClick(record)
                            : null
                        }
                      >
                        {record?.[item.original_name]}
                      </span>
                    </OverlayTrigger>
                  )
                ) : null}
              </div>
            ),
            ...(index === 0 || index === 1
              ? {
                  fixed: "left",
                }
              : {}),
          })) || [];
      setColumns([
        ...columnsData,
        {
          title: "Actions",
          dataIndex: "actions",
          align: "right",
          render: (text: string, record: any, index: number) => (
            <div className="dropdown table-action" key={index}>
              <Link
                to="#"
                className="action-icon"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i className="fa fa-ellipsis-v"></i>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{
                  position: "absolute",
                  inset: "0px auto auto 0px",
                  margin: "0px",
                  transform: "translate3d(-99.3333px, 35.3333px, 0px)",
                }}
                data-popper-placement="bottom-start"
              >
                <Link
                  className="dropdown-item edit-popup"
                  to="#"
                  onClick={() => {
                    dispatch(setEditingCompany({ editingData: record }));
                    dispatch(setActiveModal({ modalId: "add_company" }));
                  }}
                >
                  <i className="ti ti-edit text-blue"></i> Edit
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => dispatch(setRemoveCompany(record.company_id))}
                >
                  <i className="ti ti-trash text-danger"></i> Delete
                </Link>
              </div>
            </div>
          ),
        },
      ]);
    }
  }, [JSON.stringify(fieldsAdminData?.[PAGE_ROOT]?.["Company"]?.["main"])]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* company */}
              <div className="contact-head">
                <div className="row align-items-center"></div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Companies
                      {companiesPaginationData ? (
                        <span className="count-title">
                          {companiesPaginationData?.total}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          debouncedFilterSort({
                            filters: filterSearchChecked,
                            operator: filterOperator,
                            term: searchTerm,
                            order: order,
                            currentPage: pagination.current || 1,
                            pageSize: pagination.pageSize || 50,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="offset-md-5 col-md-7 offset-sm-4 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <div className="form-sorts dropdown">
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="false"
                              >
                                <i className="ti ti-filter-share" />
                                Filter
                              </Link>
                              <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-xl-end">
                                <div className="filter-set-view">
                                  <div className="filter-set-head">
                                    <h4>
                                      <i className="ti ti-filter-share" />
                                      Filter
                                    </h4>
                                    <div className="filter-set-head-right">
                                      <Select
                                        options={[
                                          { label: "Or", value: "or" },
                                          { label: "And", value: "and" },
                                        ]}
                                        value={filterOperator}
                                        onChange={(value: string) =>
                                          setFilterOperator(value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    {filterColumns.map((filterCol) => (
                                      <div
                                        className="filter-set-content"
                                        key={filterCol.original_name}
                                      >
                                        <div className="filter-set-content-head">
                                          <Link
                                            to="#"
                                            className="collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${filterCol.original_name?.toLowerCase()}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse-${filterCol.original_name?.toLowerCase()}`}
                                          >
                                            {filterCol.display_name}
                                          </Link>
                                        </div>
                                        <div
                                          className="filter-set-contents accordion-collapse collapse"
                                          id={`collapse-${filterCol.original_name?.toLowerCase()}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div className="filter-content-list">
                                            <div className="form-wrap icon-form">
                                              <span className="form-icon">
                                                <i className="ti ti-search" />
                                              </span>
                                              <SearchInput
                                                className="form-control"
                                                placeholder={`Search ${filterCol.display_name}`}
                                                onDebounce={(value: string) => {
                                                  onFieldSearch(
                                                    filterCol.original_name,
                                                    value
                                                  );
                                                }}
                                              />
                                            </div>
                                            <ul>
                                              {(
                                                fieldSearchResults?.[
                                                  filterCol.original_name
                                                ] || []
                                              ).map((searchItem) => (
                                                <li key={searchItem}>
                                                  <div className="filter-checks">
                                                    <label className="checkboxs">
                                                      <input
                                                        type="checkbox"
                                                        value={searchItem}
                                                        id={`checkebox-${filterCol.original_name}-${searchItem}`}
                                                        checked={
                                                          filterSearchChecked?.[
                                                            filterCol
                                                              .original_name
                                                          ]?.[searchItem]
                                                        }
                                                        onChange={(e) =>
                                                          onFilterItemHandler(
                                                            filterCol.original_name,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <span className="checkmarks" />
                                                    </label>
                                                  </div>
                                                  <div className="collapse-inside-text">
                                                    <label
                                                      htmlFor={`checkebox-${filterCol.original_name}-${searchItem}`}
                                                    >
                                                      <h5>{searchItem}</h5>
                                                    </label>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="filter-reset-btns">
                                    <div className="row">
                                      <div className="col-6">
                                        <Link
                                          to="#"
                                          className="btn btn-light"
                                          onClick={() => {
                                            setFilterSearchChecked({});
                                          }}
                                        >
                                          Reset
                                        </Link>
                                      </div>
                                      <div className="col-6">
                                        <Link
                                          to={"#"}
                                          className="btn btn-primary"
                                          onClick={() => {
                                            onFilterRecords();
                                          }}
                                        >
                                          Filter
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => {
                                dispatch(
                                  setEditingCompany({
                                    editingData: {
                                      company_id: "add",
                                    },
                                  })
                                );
                                dispatch(
                                  setActiveModal({ modalId: "add_company" })
                                );
                              }}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              New Company
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Company List */}
                {columns && columns.length ? (
                  <>
                    <div className="table-responsive custom-table">
                      <Table
                        dataSource={companiesData}
                        columns={columns}
                        loading={companiesDataLoading}
                        onSorterHandler={onSorterHandler}
                        pagination={{
                          position: ["bottomRight"],
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                          total: pagination.total,
                          onChange: (page, pageSize) => {
                            setPagination({
                              ...pagination,
                              current: page,
                              pageSize,
                            });
                          },
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                          locale: {
                            items_per_page: "records / page",
                          },
                        }}
                        onPaginationHandler={onPaginationHandler}
                        stickyHeader={true}
                        height={"calc(100vh - 270px)"}
                      />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="datatable-length" />
                      </div>
                      <div className="col-md-6">
                        <div className="datatable-paginate" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>No Columns</div>
                )}

                {/* /Company List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddCompanyModal />
      {/* <RemoveCompanyModal /> */}
    </>
  );
};

export default ManageCompanies;
