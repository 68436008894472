import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createLeadComment,
  deleteLeadComment,
  editLeadComment,
  getLead,
} from "../../../core/data/redux/leads/thunk";
import "./index.scss";
import { setEditingLead, setLeadViewClose } from "../../data/redux/leads/leads";
import ImageWithBasePath from "../../common/imageWithBasePath";
import Editor from "react-simple-wysiwyg";
import {
  setContactView,
  setEditingContact,
  setSelectContact,
} from "../../data/redux/contacts/contacts";
import { Empty, Flex } from "antd";
import { ToastContainer, toast } from "react-toastify";
import BaseModal from "../common/baseModal";
import {
  setCompanyView,
  setEditingCompany,
  setSelectCompany,
} from "../../data/redux/companies/companies";
import LeadService from "../../../services/leads";
import "./index.scss";
import { displayFieldDatetimeValue, isValidUrl } from "../../../utils/helper";
import { closeModal, setActiveModal } from "../../data/redux/modals/modals";

const PAGE_ROOT = "contact";

const LeadDetailModal = () => {
  const dispatch = useDispatch<any>();
  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const leadInternalDataFields = (
    fieldsAdminData?.["lead"]?.["Lead"]?.["main"]?.["data"] || []
  ).filter((field: any) => field?.is_show_popup);

  const modalToggle = useSelector(
    (state: any) => state?.lead?.leads?.view?.loading
  );
  const modalData = useSelector((state: any) => state?.lead?.leads?.view?.data);
  const { visitingLeadData } = modalData || {};

  const [isEditingComment, setIsEditingComment] = useState<{
    [commentId: string]: boolean;
  }>({});

  const [editingCommentsContent, setEditingCommentsContent] = useState<{
    [commentId: string]: string;
  }>({});

  const [activeTab, setActiveTab] = useState("notes");

  const onChange = (commentId: string, e: any) => {
    setEditingCommentsContent((prev) => ({
      ...prev,
      [commentId]: e.target.value,
    }));
  };

  const getContactEmails = (contact: any) => {
    const emails = [contact.email1, contact.email2, contact.email3].filter(
      (email: any) => email && email.length > 0
    );
    return emails.join(", ");
  };

  const getContactPhones = (contact: any) => {
    const phones = [contact.phone1, contact.phone2, contact.phone3].filter(
      (phone: any) => phone && phone.length > 0
    );
    return phones.join(", ");
  };

  const handleOnDeleteComment = async (commentId: string) => {
    try {
      await dispatch(deleteLeadComment(commentId));
      await dispatch(getLead(visitingLeadData.lead_id));
    } catch (error) {}
  };

  const handleOnSaveComment = async (commentId: string) => {
    try {
      if (commentId === "add") {
        await dispatch(
          createLeadComment({
            lead: visitingLeadData.lead_id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      } else {
        await dispatch(
          editLeadComment({
            commentId: commentId,
            lead: visitingLeadData.lead_id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      }
      await dispatch(getLead(visitingLeadData.lead_id));
      setIsEditingComment((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    } catch (error) {}
  };

  useEffect(() => {
    const leadCommentsData = (visitingLeadData?.lead_comments || []).reduce(
      (prev: any, curr: any) => {
        Object.assign(prev, { [curr.comment_id]: curr.comment });
        return prev;
      },
      {}
    );
    setEditingCommentsContent(leadCommentsData);
  }, [JSON.stringify(visitingLeadData)]);

  useEffect(() => {
    if (visitingLeadData?.lead_id) dispatch(getLead(visitingLeadData.lead_id));
  }, [visitingLeadData?.lead_id]);

  // if (isLeadDataLoading) {
  //   return (
  //     <div className="page-wrapper">
  //       <div className="content">
  //         <div className="preloader">
  //           <div className="preloader">
  //             <div className="loader"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setLeadViewClose());
        dispatch(closeModal("detail_lead"));
      }}
      title="Lead Detail"
      className="sidebar-popup"
      modalId={"detail_lead"}
    >
      <div className="pro-create">
        <div className="row">
          <div className="col-xl-12">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link
                    to="#"
                    className={activeTab === "notes" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("notes");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Notes
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={activeTab === "basic" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("basic");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Basic Info
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={activeTab === "contacts" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("contacts");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Contacts
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={activeTab === "companies" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("companies");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Companies
                  </Link>
                </li>
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* Notes */}
                <div
                  className={`tab-pane ${
                    activeTab === "notes" ? "active show" : ""
                  }`}
                  id="notes"
                >
                  <div className="view-header">
                    <h4>Notes</h4>
                    <div className="text-end">
                      <Link
                        to="#"
                        className="add-comment"
                        onClick={() => {
                          setIsEditingComment((prev) => ({
                            ...prev,
                            ["add"]: true,
                          }));
                        }}
                      >
                        <i className="ti ti-square-plus me-1" />
                        Add Comment
                      </Link>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <div
                      className="calls-box"
                      style={{
                        display: isEditingComment?.["add"] ? "block" : "none",
                      }}
                    >
                      <div className="notes-editor">
                        <div className="note-edit-wrap d-block">
                          <div className="summernote">
                            <Editor
                              value={editingCommentsContent?.["add"]}
                              onChange={(e) => onChange("add", e)}
                            />
                          </div>
                          <div className="text-end note-btns">
                            <Link
                              to="#"
                              className="btn btn-light add-cancel"
                              onClick={() => {
                                setIsEditingComment((prev) => ({
                                  ...prev,
                                  ["add"]: false,
                                }));
                                setEditingCommentsContent((prev) => ({
                                  ...prev,
                                  ["add"]: "",
                                }));
                              }}
                            >
                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-primary"
                              onClick={() => handleOnSaveComment("add")}
                            >
                              Save
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {visitingLeadData?.lead_comments?.length ? (
                      (visitingLeadData?.lead_comments || [])?.map(
                        (leadComment: any) => (
                          <div
                            className="calls-box"
                            key={leadComment.comment_id}
                          >
                            <div className="caller-info">
                              <div className="calls-user">
                                <ImageWithBasePath
                                  src="assets/img/profiles/avatar-19.jpg"
                                  alt="img"
                                />
                                <div>
                                  <h6>
                                    {`${leadComment.user.first_name} ${leadComment.user.last_name}`.trim() ||
                                      leadComment.user.username}
                                  </h6>
                                  <p>{leadComment?.created_at}</p>
                                </div>
                              </div>
                              <div className="calls-action">
                                <div className="dropdown action-drop">
                                  <Link
                                    to="#"
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-dots-vertical" />
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() => {
                                        setIsEditingComment((prev) => ({
                                          ...prev,
                                          [leadComment.comment_id]: true,
                                        }));
                                      }}
                                    >
                                      <i className="ti ti-edit text-blue" />
                                      Edit
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        handleOnDeleteComment(
                                          leadComment.comment_id
                                        )
                                      }
                                    >
                                      <i className="ti ti-trash text-danger" />
                                      Delete
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p>{leadComment?.comment}</p>
                            <div className="notes-editor">
                              <div
                                className="note-edit-wrap"
                                style={{
                                  display: isEditingComment?.[
                                    leadComment?.comment_id
                                  ]
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <div className="summernote">
                                  <Editor
                                    value={
                                      editingCommentsContent?.[
                                        leadComment.comment_id
                                      ]
                                    }
                                    onChange={(e) =>
                                      onChange(leadComment.comment_id, e)
                                    }
                                  />
                                </div>
                                <div className="text-end note-btns">
                                  <Link
                                    to="#"
                                    className="btn btn-light add-cancel"
                                    onClick={() => {
                                      setIsEditingComment((prev) => ({
                                        ...prev,
                                        [leadComment.comment_id]: false,
                                      }));
                                      setEditingCommentsContent((prev) => ({
                                        ...prev,
                                        [leadComment.comment_id]:
                                          leadComment?.comment || "",
                                      }));
                                    }}
                                  >
                                    Cancel
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleOnSaveComment(
                                        leadComment.comment_id
                                      )
                                    }
                                  >
                                    Save
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="calls-box">No Notes</div>
                    )}
                  </div>
                </div>
                {/* /Notes */}

                {/* Basic */}
                <div
                  className={`tab-pane ${
                    activeTab === "basic" ? "active show" : ""
                  }`}
                  id="basic"
                >
                  <div className="view-header">
                    <h4>Detail</h4>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch(
                          setEditingLead({
                            editingData: visitingLeadData,
                            pageRoot: "lead",
                            modelCategory: "main",
                            onSubmitFinish: () => {
                              dispatch(getLead(visitingLeadData?.lead_id));
                            },
                          })
                        );
                        dispatch(setActiveModal({ modalId: "add_lead" }));
                      }}
                    >
                      <i className="ti ti-edit mr-2"></i> Edit
                    </button>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      {(leadInternalDataFields || []).map((field: any) => (
                        <li key={field.original_name} className="detail-item">
                          <div className="other-title h6">
                            {field.display_name}
                          </div>
                          <div>
                            {field.display_field_type === "boolean" ? (
                              <Flex gap="4px 0" className="align-items-center">
                                <div className="form-switch form-check-md">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={
                                      visitingLeadData?.[field.original_name] ||
                                      false
                                    }
                                    disabled
                                  />
                                </div>
                              </Flex>
                            ) : field.display_field_type === "datetime" ||
                              field.display_field_type === "date" ? (
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {displayFieldDatetimeValue(
                                  visitingLeadData?.[field.original_name],
                                  field.display_field_type
                                )}
                              </div>
                            ) : (
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {visitingLeadData?.[field.original_name]
                                  ? typeof visitingLeadData?.[
                                      field.original_name
                                    ] === "object"
                                    ? Object.entries(
                                        visitingLeadData?.[
                                          field.original_name
                                        ] || {}
                                      ).map(([key, value]: [string, any]) =>
                                        value ? (
                                          <div>
                                            <span>{key}</span>:
                                            <span>{value}</span>
                                          </div>
                                        ) : null
                                      )
                                    : visitingLeadData?.[field.original_name]
                                  : ""}
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* /Basic */}

                {/* Basic */}
                <div
                  className={`tab-pane ${
                    activeTab === "contacts" ? "active show" : ""
                  }`}
                  id="contacts"
                >
                  <div className="view-header">
                    <h4>Contacts</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Contact
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectContact({
                                  onSubmitFinish: async (
                                    contact_id: string
                                  ) => {
                                    try {
                                      const response =
                                        await LeadService.addContactToLead({
                                          lead_id: visitingLeadData?.lead_id,
                                          contact_id: contact_id,
                                        });
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getLead(visitingLeadData?.lead_id)
                                      );
                                      toast.success(
                                        "Contact Added Successfully",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message ||
                                          "Failed to Add Contact",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "select_contact" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Contact
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingContact({
                                  editingData: {
                                    contact_id: "add",
                                    lead_id: visitingLeadData?.lead_id,
                                  },
                                  onSubmitFinish: (lead_id: string) => {
                                    dispatch(getLead(lead_id));
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "add_contact" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Contact
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingLeadData?.contacts?.length ? (
                          (visitingLeadData?.contacts || []).map(
                            (contact: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={contact.contact_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-contact-${contact.contact_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {contact.contact_id}
                                      </span>
                                      <span className="me-3">
                                        {contact.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setContactView({
                                                visitingContactData: contact,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_contact",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            try {
                                              const response =
                                                await LeadService.addContactToLead(
                                                  {
                                                    lead_id:
                                                      visitingLeadData?.lead_id,
                                                    contact_id:
                                                      contact?.contact_id,
                                                    is_add: false,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getLead(
                                                  visitingLeadData?.lead_id
                                                )
                                              );
                                              toast.success(
                                                "Contact Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Contact",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-contact-${contact.contact_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.[PAGE_ROOT]?.[
                                        "ContactNew"
                                      ]?.["main"]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              <span>
                                                {contact?.[
                                                  field.original_name
                                                ] ? (
                                                  isValidUrl(
                                                    contact[field.original_name]
                                                  ) ? (
                                                    <div
                                                      className="text-truncate"
                                                      style={{
                                                        maxWidth: "250px",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          contact[
                                                            field.original_name
                                                          ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {
                                                          contact[
                                                            field.original_name
                                                          ]
                                                        }
                                                      </a>
                                                    </div>
                                                  ) : typeof contact?.[
                                                      field.original_name
                                                    ] === "object" ? (
                                                    Object.entries(
                                                      contact?.[
                                                        field.original_name
                                                      ] || {}
                                                    ).map(
                                                      ([key, value]: [
                                                        string,
                                                        any
                                                      ]) =>
                                                        value ? (
                                                          <div>
                                                            <span>{key}</span>:
                                                            <span>{value}</span>
                                                          </div>
                                                        ) : null
                                                    )
                                                  ) : field.display_field_type ===
                                                      "datetime" ||
                                                    field.display_field_type ===
                                                      "date" ? (
                                                    displayFieldDatetimeValue(
                                                      contact?.[
                                                        field.original_name
                                                      ],
                                                      field.display_field_type
                                                    )
                                                  ) : (
                                                    contact?.[
                                                      field.original_name
                                                    ]
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                {/* /Contacts */}

                <div
                  className={`tab-pane ${
                    activeTab === "companies" ? "active show" : ""
                  }`}
                  id="companies"
                >
                  <div className="view-header">
                    <h4>Companies</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Company
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectCompany({
                                  onSubmitFinish: async (
                                    company_id: string
                                  ) => {
                                    try {
                                      const response =
                                        await LeadService.addCompanyToLead({
                                          lead_id: visitingLeadData?.lead_id,
                                          company_id: company_id,
                                        });
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getLead(visitingLeadData?.lead_id)
                                      );
                                      toast.success(
                                        "Company Added Successfully",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message ||
                                          "Failed to Add Company",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "select_company" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Company
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingCompany({
                                  editingData: {
                                    company_id: "add",
                                    lead_id: visitingLeadData?.lead_id,
                                  },
                                  onSubmitFinish: (lead_id: string) => {
                                    dispatch(getLead(lead_id));
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "add_company" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Company
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingLeadData?.companies?.length ? (
                          (visitingLeadData?.companies || []).map(
                            (company: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={company.company_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-company-${company.company_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {company.company_id}
                                      </span>
                                      <span className="me-3">
                                        {company.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setCompanyView({
                                                visitingCompanyData: company,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_company",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            try {
                                              const response =
                                                await LeadService.addCompanyToLead(
                                                  {
                                                    lead_id:
                                                      visitingLeadData?.lead_id,
                                                    company_id:
                                                      company?.company_id,
                                                    is_add: false,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getLead(
                                                  visitingLeadData?.lead_id
                                                )
                                              );
                                              toast.success(
                                                "Company Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Company",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-company-${company.company_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.[PAGE_ROOT]?.[
                                        "Company"
                                      ]?.["main"]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              {company?.[
                                                field.original_name
                                              ] ? (
                                                isValidUrl(
                                                  company[field.original_name]
                                                ) ? (
                                                  <div
                                                    className="text-truncate"
                                                    style={{
                                                      maxWidth: "250px",
                                                    }}
                                                  >
                                                    <a
                                                      href={
                                                        company[
                                                          field.original_name
                                                        ]
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {
                                                        company[
                                                          field.original_name
                                                        ]
                                                      }
                                                    </a>
                                                  </div>
                                                ) : typeof company?.[
                                                    field.original_name
                                                  ] === "object" ? (
                                                  Object.entries(
                                                    company?.[
                                                      field.original_name
                                                    ] || {}
                                                  ).map(
                                                    ([key, value]: [
                                                      string,
                                                      any
                                                    ]) =>
                                                      value ? (
                                                        <div>
                                                          <span>{key}</span>:
                                                          <span>{value}</span>
                                                        </div>
                                                      ) : null
                                                  )
                                                ) : field.display_field_type ===
                                                    "datetime" ||
                                                  field.display_field_type ===
                                                    "date" ? (
                                                  displayFieldDatetimeValue(
                                                    company?.[
                                                      field.original_name
                                                    ],
                                                    field.display_field_type
                                                  )
                                                ) : (
                                                  company?.[field.original_name]
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Tab Content */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </BaseModal>
  );
};

export default LeadDetailModal;
