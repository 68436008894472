import { sortBy } from "lodash";
import { all_routes } from "../feature-module/router/all_routes";
import axios from "./axios";
import secureLocalStorage from "react-secure-storage";
const localStorage = secureLocalStorage;

export const setSession = (accessToken: any, refreshToken: any) => {
  if (accessToken && refreshToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    console.log(accessToken, "DEBUG::::::", accessToken, refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export const findParents = (
  record: any,
  groupData: any[],
  menuData: any[]
): any[] => {
  if (record?.group) {
    const data = groupData.find((group: any) => group.id === record.group);
    return [
      {
        is_group: true,
        data,
      },
      ...findParents(data ? data : record.group, groupData, menuData),
    ];
  } else if (record?.parent) {
    const data = menuData.find((menu: any) => menu.id === record.parent);
    return [
      {
        is_group: false,
        data,
      },
      ...findParents(data ? data : record.parent, groupData, menuData),
    ];
  }
  return [];
};

const isAdminPage = (pageCategory: string) =>
  pageCategory.includes("Admin Page");

const getAdminPageRoute = (pageCategory: string, allRoutes: any) => {
  switch (pageCategory) {
    case "Contact Admin Page":
      return allRoutes.adminContact;
    case "Lead Admin Page":
      return allRoutes.adminLead;
    case "Seagments Page":
      return allRoutes.adminSeagment;
    case "Closed Lead Admin Page":
      return allRoutes.adminClosedLead;
    case "Company Admin Page":
      return allRoutes.adminCompany;
    default:
      return allRoutes.adminContact;
  }
};

export const getSideBarData = (data: any[], menuData: any[]) => {
  const route = all_routes;
  const sidebarData: any[] = data.map((dataItem: any) => {
    const childData: any[] = sortBy(
      menuData.filter((menuItem) =>
        dataItem?.child_menus_ids
          ? menuItem?.parent === dataItem?.id ||
            menuItem?.parent?.id === dataItem?.id
          : menuItem?.group === dataItem?.id ||
            menuItem?.group?.id === dataItem?.id
      ),
      "order"
    );
    return {
      id: dataItem.id,
      label: dataItem.name,
      submenu: childData?.length ? true : false,
      showSubRoute: false,
      submenuHdr: dataItem.id,
      data: dataItem,
      link:
        dataItem?.menu_page_category === "Contact Page"
          ? route.contacts
          : dataItem?.menu_page_category === "Lead Page"
          ? route.leads
          : dataItem?.menu_page_category === "Company Page"
          ? route.companies
          : dataItem?.menu_page_category === "Campaign Page"
          ? dataItem?.url?.replace(process.env.REACT_APP_CLIENT_URL, "")
          : isAdminPage(dataItem?.menu_page_category || "")
          ? getAdminPageRoute(dataItem?.menu_page_category || "", all_routes)
          : dataItem?.url
          ? route.appConnectivity.replace(":menuId", dataItem.id)
          : "#",
      icon: dataItem?.menu_icon ?? "",
      submenuItems: getSideBarData(childData, menuData),
    };
  });

  return sidebarData;
};

export const getFlattenSideBarData = (data: any[]) => {
  const route = all_routes;

  const sidebarData: any[] = data.map((dataItem: any) => {
    return {
      id: dataItem.id,
      label: dataItem.name,
      submenu: false,
      showSubRoute: false,
      submenuHdr: dataItem.id,
      data: dataItem,
      link:
        dataItem?.menu_page_category === "Contact Page"
          ? route.contacts
          : dataItem?.menu_page_category === "Lead Page"
          ? route.leads
          : dataItem?.menu_page_category === "Company Page"
          ? route.companies
          : dataItem?.menu_page_category === "Campaign Page"
          ? dataItem?.url?.replace(process.env.REACT_APP_CLIENT_URL, "")
          : isAdminPage(dataItem?.menu_page_category || "")
          ? getAdminPageRoute(dataItem?.menu_page_category || "", all_routes)
          : dataItem?.url
          ? route.appConnectivity.replace(":menuId", dataItem.id)
          : "#",
      icon: dataItem?.menu_icon ?? "",
    };
  });

  return [
    {
      id: "search",
      label: "Menus",
      submenu: true,
      showSubRoute: false,
      submenuHdr: "search",
      data: null,
      link: "#",
      icon: "",
      submenuItems: sidebarData,
    },
  ];
};

export const getErrorMessage = (error: any) => {
  return (
    error?.message ||
    error?.detail ||
    error?.["non_field_errors"]?.[0] ||
    "Something went wrong"
  );
};

export const isValidUrl = (string: string): boolean => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export const displayFieldDatetimeValue = (
  value: any,
  type: "datetime" | "date" = "datetime"
): any => {
  // Check if value is null or undefined
  if (
    value === null ||
    value === undefined ||
    !value ||
    value?.toLowerCase() === "null"
  ) {
    return "";
  }

  const dateValue = new Date(value);
  if (typeof value === "string" && !isNaN(dateValue.getTime())) {
    if (type === "date") {
      return dateValue.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
    return dateValue.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // Return value as is for other types
  return value;
};
