import { createAsyncThunk } from "@reduxjs/toolkit";
import SeagmentService from "../../../../services/seagments";

// Seagment Actions
export const getSeagments = createAsyncThunk(
  "seagment/retrieve-seagments",
  async () => {
    const response = await SeagmentService.getSeagments();
    if (!response) {
      throw new Error("Retrieve Seagments Failed");
    }

    return response;
  }
);

export const filterSortSeagments = createAsyncThunk(
  "seagment/filter-sort-seagments",
  async (payload: any) => {
    const { filters, term, operator, order, currentPage, pageSize } = payload;
    const response = await SeagmentService.filterSortSeagments(
      filters,
      term,
      true,
      operator,
      order,
      currentPage,
      pageSize
    );
    if (!response) {
      throw new Error("Filter Seagments Failed");
    }

    return response;
  }
);

export const getSeagment = createAsyncThunk(
  "seagment/retrieve-seagment",
  async (payload: any) => {
    const response = await SeagmentService.getSeagment(payload);
    if (!response) {
      throw new Error("Retrieve Seagment Failed");
    }

    return response;
  }
);

export const createSeagment = createAsyncThunk(
  "seagment/create-seagment",
  async (payload: any) => {
    const response = await SeagmentService.createSeagment(payload);
    if (!response) {
      throw new Error("Create Seagment Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editSeagment = createAsyncThunk(
  "seagment/edit-seagment",
  async (payload: any) => {
    const response = await SeagmentService.editSeagment(payload);
    if (!response) {
      throw new Error("Edit Seagment Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteSeagment = createAsyncThunk(
  "seagment/delete-seagment",
  async (payload: any) => {
    const response = await SeagmentService.removeSeagment(payload);
    if (!response) {
      throw new Error("Delete Seagment Failed!");
    }

    return response;
  }
);