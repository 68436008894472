import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../../core/common/collapse-header";
import { Flex, Tag } from "antd";
import {
  editAdminField,
  getAdminFields,
  retrieveAdminFields,
} from "../../../core/data/redux/fieldsAdmin/thunk";
import {
  setEditFieldsAdmin,
  setRemoveFieldsAdmin,
} from "../../../core/data/redux/fieldsAdmin/fieldsAdmin";
import AddFieldAdminModal from "../../../core/modals/admin/fieldAdmin/add_field_admin_modal";
import RemoveFieldAdminModal from "../../../core/modals/admin/fieldAdmin/remove_field_admin_modal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PAGE_ROOT = "contact";

const AdminCompanies = () => {
  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const fieldsAdminDataLoading = useSelector(
    (state: any) => state.fieldsAdmin.admin?.loading
  );

  const dispatch = useDispatch<any>();
  const [modelName, setModelName] = useState<string | undefined>();
  const [modelCategory, setModelCategory] = useState<string | undefined>();

  const columns = useMemo(() => {
    return [
      {
        title: "",
        dataIndex: "sort",
        width: 60,
        className: "drag-visible",
      },
      {
        title: "Original Field Name",
        dataIndex: "original_name",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <div
            className="table-avatar d-flex align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-original_name`}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  id={`${index}-original_name-tooltip`}
                  style={{ position: "fixed" }}
                >
                  {record.original_name}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record.original_name}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Original Field Type",
        dataIndex: "original_field_type",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <div
            className="table-avatar d-flex align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-original_field_type`}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  id={`${index}-original_field_type-tooltip`}
                  style={{ position: "fixed" }}
                >
                  {record.original_field_type}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record.original_field_type}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Display Field Name",
        dataIndex: "display_name",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <div
            className="table-avatar d-flex align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-display_name`}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  id={`${index}-display_name-tooltip`}
                  style={{ position: "fixed" }}
                >
                  {record.display_name}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record.display_name}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Display Field Type",
        dataIndex: "display_field_type",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <div
            className="table-avatar d-flex align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-display_field_type`}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  id={`${index}-display_field_type-tooltip`}
                  style={{ position: "fixed" }}
                >
                  {record.display_field_type}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record.display_field_type}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },

      {
        title: "Assigned Groups",
        dataIndex: "assigned_user_groups",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <Flex
            gap="4px 0"
            wrap="wrap"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-assigned_user_groups`}
          >
            {(record?.assigned_user_groups || []).map((group: any) => (
              <Tag color="processing" key={group.name}>
                {group.name}
              </Tag>
            ))}
          </Flex>
        ),
      },
      {
        title: "Is Show Manage Table",
        dataIndex: "is_show_manage_table",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <Flex
            gap="4px 0"
            className="align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-is_show_manage_table`}
          >
            <div className="form-switch form-check-md">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={record.is_show_manage_table}
                disabled
              />
            </div>
          </Flex>
        ),
      },
      {
        title: "Is Popup Shown",
        dataIndex: "is_show_popup",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <Flex
            gap="4px 0"
            className="align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-is_show_popup`}
          >
            <div className="form-switch form-check-md">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={record.is_show_popup}
                disabled
              />
            </div>
          </Flex>
        ),
      },
      {
        title: "Is Required Popup",
        dataIndex: "is_required_popup",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <Flex
            gap="4px 0"
            className="align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-is_required_popup`}
          >
            <div className="form-switch form-check-md">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={record.is_required_popup}
                disabled
              />
            </div>
          </Flex>
        ),
      },
      {
        title: "Is Filter",
        dataIndex: "is_filter",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <Flex
            gap="4px 0"
            className="align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-is_filter`}
          >
            <div className="form-switch form-check-md">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={record.is_filter}
                disabled
              />
            </div>
          </Flex>
        ),
      },
      {
        title: "Width",
        dataIndex: "width",
        ellipsis: true,
        render: (text: string, record: any, index: number) => (
          <div
            className="table-avatar d-flex align-items-center"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-width`}
          >
            <span className="profile-split d-flex flex-column cursor-pointer">
              {record.width}px
            </span>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text: string, record: any, index: number) => (
          <div
            className="dropdown table-action"
            key={`${record?.page_root}-${record?.model_name}-${record?.model_category}-${index}-actions`}
          >
            <Link
              to="#"
              className="action-icon"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v"></i>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              style={{
                position: "absolute",
                inset: "0px auto auto 0px",
                margin: "0px",
                transform: "translate3d(-99.3333px, 35.3333px, 0px)",
              }}
              data-popper-placement="bottom-start"
            >
              <Link
                className="dropdown-item edit-popup"
                to="#"
                onClick={() => dispatch(setEditFieldsAdmin(record))}
              >
                <i className="ti ti-edit text-blue"></i> Edit
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => dispatch(setRemoveFieldsAdmin(record))}
              >
                <i className="ti ti-trash text-danger"></i> Delete
              </Link>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    const init = async () => {
      dispatch(
        getAdminFields({
          page_root: PAGE_ROOT,
        })
      );
    };

    init();
  }, [dispatch]);

  const onDragEnd = async (recordId: string, newOrder: number) => {
    await dispatch(
      editAdminField({
        id: recordId,
        data: {
          order: newOrder + 1,
        },
        is_new_order: true,
      })
    );
    dispatch(
      getAdminFields({
        page_root: PAGE_ROOT,
      })
    );
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">Company Admin</h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(
                            getAdminFields({
                              page_root: PAGE_ROOT,
                              model_name: "Company",
                              model_category: "main",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="offset-md-5 col-md-7 offset-sm-4 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          {!fieldsAdminData[PAGE_ROOT]?.["Company"]?.["main"]
                            ?.data?.length && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(
                                    retrieveAdminFields({
                                      page_root: PAGE_ROOT,
                                      model_name: "Company",
                                      model_category: "main",
                                    })
                                  )
                                }
                              >
                                <i className="ti ti-square-rounded-plus" />{" "}
                                Retrieve Company Admin
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => {
                                setModelName("Company");
                                setModelCategory("main");
                                dispatch(setEditFieldsAdmin(null));
                              }}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              New Field Admin
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Company Admin List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={
                      fieldsAdminData[PAGE_ROOT]?.["Company"]?.["main"]?.data
                    }
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={
                      fieldsAdminDataLoading ||
                      fieldsAdminData[PAGE_ROOT]?.["Company"]?.["main"]
                        ?.loading
                    }
                    stickyHeader={true}
                    height={"calc(100vh - 270px)"}
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Company Admin List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddFieldAdminModal
        pageRoot={PAGE_ROOT}
        modelName={modelName}
        modelCategory={modelCategory}
      />
      <RemoveFieldAdminModal />
    </>
  );
};

export default AdminCompanies;
