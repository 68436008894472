import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyService from "../../../../services/company";

// Company Actions
export const getCompanies = createAsyncThunk(
  "company/retrieve-companies",
  async (payload: any) => {
    const response = await CompanyService.getCompanies(payload);
    if (!response) {
      throw new Error("Retrieve Companies Failed");
    }

    return response;
  }
);

export const filterSortCompanies = createAsyncThunk(
  "company/filter-sort-companies",
  async (payload: any) => {
    const { filters, term, operator, order, currentPage, pageSize } = payload;
    const response = await CompanyService.filterSortCompanies(
      filters,
      term,
      true,
      operator,
      order,
      currentPage,
      pageSize
    );
    if (!response) {
      throw new Error("Filter Companies Failed");
    }

    return response;
  }
);

export const getCompany = createAsyncThunk(
  "company/retrieve-company",
  async (payload: any) => {
    const response = await CompanyService.getCompany(payload);
    if (!response) {
      throw new Error("Retrieve Company Failed");
    }

    return response;
  }
);

export const createCompany = createAsyncThunk(
  "company/create-company",
  async (payload: any) => {
    const response = await CompanyService.createCompany(payload);
    if (!response) {
      throw new Error("Create Company Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editCompany = createAsyncThunk(
  "company/edit-company",
  async (payload: any) => {
    const response = await CompanyService.editCompany(payload);
    if (!response) {
      throw new Error("Edit Company Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteCompany = createAsyncThunk(
  "company/delete-company",
  async (payload: any) => {
    const response = await CompanyService.removeCompany(payload);
    if (!response) {
      throw new Error("Delete Company Failed!");
    }

    return response;
  }
);
