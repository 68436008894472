import React, { useState } from "react";
import Select from "react-select";

interface OptionType {
  value: string;
  label: string;
  additionalInfo: string | null;
}

interface MultiSelectBoxProps {
  label: string;
  options: OptionType[];
	selected: OptionType[];
  onSelectOptions: (selected: OptionType[]) => void;
  onClearOptions: () => void;
}

// Custom Selected Value Display (for selected items)
const CustomMultiValue = (props: any) => {
  const { data, removeProps } = props;
  return (
    <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded flex items-center space-x-2">
      <span>{data.label}</span>
      <button {...removeProps} className="text-red-500 ml-2 hover:text-red-700">
        ×
      </button>
    </div>
  );
};

// Custom component for dropdown options
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="p-2 flex justify-between border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
    >
      <span className="font-medium">{data.label}</span>
			<br />
      <span className="text-gray-500">{data.additionalInfo}</span>
    </div>
  );
};

const MultiSelectBox: React.FC<MultiSelectBoxProps> = ({
  label = "Select Items",
  options = [],
	selected = [],
  onSelectOptions,
  onClearOptions,
}) => {

  const handleChange = (selected: any[]) => {
    const newSelected = selected || [];
    onSelectOptions(newSelected);
  };

  const handleClear = () => {
    onSelectOptions([]);
    onClearOptions();
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <Select
        isMulti
        options={options}
        value={selected}
        onChange={(values: any) => handleChange(values)}
        className="mt-1"
        components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
      />
    </div>
  );
};

export default MultiSelectBox;
