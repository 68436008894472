import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface BaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  className?: string;
  isCenter?: boolean;
  isLarge?: boolean;
  modalId?: string;
}

const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  isCenter = false,
  isLarge = false,
  className = "",
  modalId = "",
}) => {
  // Example usage in a modal component
  const modalState = useSelector((state: any) => state.modal.activeModals);
  const thisModal = modalState[modalId];

  // Get the highest order number from active modals
  const highestOrder = Math.max(
    ...Object.values(modalState).map((modal: any) => modal.order)
  );

  if (!isOpen) return null;

  return isCenter ? (
    <div
      className={`modal custom-modal fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      role="dialog"
    >
      <div className={`modal-dialog ${isLarge ? 'modal-lg' : ''} modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header border-0 m-0 justify-content-between">
            <h4>{title}</h4>
            <button className="btn-close" onClick={onClose} aria-label="Close">
              <i className="ti ti-x" />
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`toggle-popup ${className} ${
        thisModal?.order === highestOrder ? "d-block" : "d-none"
      }`}
      style={{}}
    >
      <div className="sidebar-layout">
        <div className="sidebar-header">
          <h4>{title}</h4>
          <Link to="#" className="sidebar-close toggle-btn" onClick={onClose}>
            <i className="ti ti-x" />
          </Link>
        </div>
        <div className="toggle-body">{children}</div>
      </div>
    </div>
  );
};

export default BaseModal;
