import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../../core/common/collapse-header";
import pkg from "lodash";
import { SorterResult } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib/table";
import { setActiveModal } from "../../../core/data/redux/modals/modals";
import {
  setEditingSeagment,
  setRemoveSeagment,
} from "../../../core/data/redux/seagments/seagments";
import { filterSortSeagments } from "../../../core/data/redux/seagments/thunk";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { displayFieldDatetimeValue } from "../../../utils/helper";
import SearchInput from "../../../core/common/searchInput/searchInput";

const { debounce } = pkg;

const ManageSeagments = () => {
  const dispatch = useDispatch<any>();

  const seagmentsData =
    useSelector((state: any) => state.seagment.seagments?.list) || [];

  const seagmentsPaginationData =
    useSelector((state: any) => state.seagment.seagments?.pagination) || {};

  const seagmentsDataLoading = useSelector(
    (state: any) => state.seagment.seagments?.loading
  );

  const handleRowClick = (contact: any) => {
    if (contact) {
      dispatch();
      // setContactView({
      //   visitingContactData: contact,
      //   onMergeHandler: () => {
      //     debouncedFilterSort({
      //       filters: filterSearchChecked,
      //       operator: filterOperator,
      //       term: searchTerm,
      //       order: order,
      //       currentPage: pagination.current,
      //       pageSize: pagination.pageSize,
      //     });
      //   },
      // })
      dispatch(
        setActiveModal({
          modalId: "detail_seagment",
        })
      );
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Seagment Id",
        dataIndex: "seagment_id",
        key: "seagment_id",
        width: 150,
        align: "left",
        ellipsis: true,
        sorter: true,
        sortDirections: ["ascend", "descend", null],
        render: (text: string, record: any, index: number) => (
          <div
            className={`table-avatar d-flex justify-content-start align-items-center`}
            key={index}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
                  {record?.["seagment_id"]}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer text-decoration-underline`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={() => handleRowClick(record)}
              >
                {record?.["seagment_id"]}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 300,
        align: "left",
        ellipsis: true,
        sorter: true,
        sortDirections: ["ascend", "descend", null],
        render: (text: string, record: any, index: number) => (
          <div
            className={`table-avatar d-flex justify-content-start align-items-center`}
            key={index}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
                  {record?.["name"]}
                </Tooltip>
              }
            >
              <span
                className={`profile-split`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record?.["name"]}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        width: 150,
        align: "left",
        ellipsis: true,
        sorter: true,
        sortDirections: ["ascend", "descend", null],
        render: (text: string, record: any, index: number) => (
          <div
            className={`table-avatar d-flex justify-content-start align-items-center`}
            key={index}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
                  {record?.["service"]}
                </Tooltip>
              }
            >
              <span
                className={`profile-split`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {record?.["service"]}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Filters",
        dataIndex: "filters",
        key: "filters",
        width: 500,
        align: "left",
        ellipsis: true,
        sorter: true,
        sortDirections: ["ascend", "descend", null],
        render: (text: string, record: any, index: number) => {
          const filters = record?.["filters"] || {};
          const filterObj = filters["filters"] || {};
          const filterCount = Object.keys(filterObj).length;

          return (
            <div
              className={`table-avatar d-flex justify-content-start align-items-center`}
              key={index}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
                    {filterCount > 0
                      ? `${filterCount} filters`
                      : "No filters"}
                  </Tooltip>
                }
              >
                <span className={`profile-split`}>
                  {filterCount > 0 ? (
                    <div className="d-flex align-items-center">
                      <span className="filter-operator text-capitalize me-2">
                        Filters:
                      </span>
                      <span className="filter-count">
                        {filterCount} filters
                      </span>
                    </div>
                  ) : (
                    "No filters"
                  )}
                </span>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        width: 150,
        align: "left",
        ellipsis: true,
        sorter: true,
        sortDirections: ["ascend", "descend", null],
        render: (text: string, record: any, index: number) => (
          <div
            className={`table-avatar d-flex justify-content-start align-items-center`}
            key={index}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
                  {displayFieldDatetimeValue(
                    record?.["created_at"],
                    "datetime"
                  )}
                </Tooltip>
              }
            >
              <span
                className={`profile-split cursor-pointer`}
                style={{
                  cursor: "pointer",
                  wordBreak: "keep-all",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {displayFieldDatetimeValue(record?.["created_at"], "datetime")}
              </span>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        align: "right",
        render: (text: string, record: any, index: number) => (
          <div className="dropdown table-action" key={index}>
            <Link
              to="#"
              className="action-icon"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <i className="fa fa-ellipsis-v"></i>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              style={{
                position: "absolute",
                inset: "0px auto auto 0px",
                margin: "0px",
                transform: "translate3d(-99.3333px, 35.3333px, 0px)",
              }}
              data-popper-placement="bottom-start"
            >
              <Link
                className="dropdown-item edit-popup"
                to="#"
                onClick={() => {
                  dispatch(
                    setEditingSeagment({
                      editingData: record,
                    })
                  );
                  dispatch(setActiveModal({ modalId: "add_seagment" }));
                }}
              >
                <i className="ti ti-edit text-blue"></i> Edit
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => {
                  dispatch(setRemoveSeagment(record.seagment_id));
                }}
              >
                <i className="ti ti-trash text-danger"></i> Delete
              </Link>
            </div>
          </div>
        ),
      },
    ];
  }, []);

  const [searchTerm, setSearchTerm] = useState<any>("");

  const [filterSearchChecked, setFilterSearchChecked] = useState<{
    [key: string]: {
      [term: string]: true;
    };
  }>({});

  const [filterOperator, setFilterOperator] = useState<string>("or");

  const [order, setOrder] = useState<{
    [field: string]: string | null;
  }>({
    seagment_id: "desc",
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
    total: 0,
  });

  const onSearch = (value: string) => {
    if (!value || !value.length) {
      setSearchTerm(null);
      return;
    }

    if (value?.length >= 3) {
      setSearchTerm(value);
    }
  };

  const debouncedFilterSort = useMemo(
    () =>
      debounce(
        (params: {
          filters?: any;
          operator?: string;
          term?: string;
          order?: any;
          currentPage?: number;
          pageSize?: number;
        }) => {
          dispatch(
            filterSortSeagments({
              filters: params.filters || filterSearchChecked,
              term: params.term || searchTerm,
              operator: params.operator || filterOperator,
              order: params.order || order,
              currentPage: params.currentPage || pagination.current,
              pageSize: params.pageSize || pagination.pageSize,
            })
          );
        },
        1000
      ),
    [dispatch]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedFilterSort.cancel();
    };
  }, []);

  const onPaginationHandler = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const onSorterHandler = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    if (Array.isArray(sorter)) {
      const result: {
        [key: string]: string | null;
      } = {};
      sorter.map((sortItem: SorterResult<any>) => {
        const currentOrder =
          order?.[sortItem.columnKey as string] === undefined
            ? "ascend"
            : order?.[sortItem.columnKey as string] === null
            ? null
            : order?.[sortItem.columnKey as string];
        result[sortItem.columnKey as string] =
          currentOrder === "ascend"
            ? "descend"
            : currentOrder === "descend"
            ? null
            : "ascend";
      });
      setOrder((_) => {
        return {
          ...result,
        };
      });
    } else {
      if (sorter.columnKey) {
        setOrder((_) => {
          const currentOrder =
            order?.[sorter.columnKey as string] === undefined
              ? "ascend"
              : order?.[sorter.columnKey as string] === null
              ? null
              : order?.[sorter.columnKey as string];
          return {
            [sorter.columnKey as string]:
              currentOrder === "ascend"
                ? "descend"
                : currentOrder === "descend"
                ? null
                : "ascend",
          };
        });
      }
    }
  };

  useEffect(() => {
    setPagination(seagmentsPaginationData);
  }, [JSON.stringify(seagmentsPaginationData)]);

  useEffect(() => {
    debouncedFilterSort({
      filters: filterSearchChecked,
      operator: filterOperator,
      term: searchTerm,
      order: order,
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  }, [
    pagination.current,
    pagination.pageSize,
    searchTerm,
    JSON.stringify(order),
  ]);

  // useEffect(() => {
  //   if (
  //     fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.["data"]?.length
  //   ) {
  //     const filters = fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.[
  //       "data"
  //     ]?.filter(
  //       (fieldConfiguration: any) =>
  //         fieldConfiguration.is_filter &&
  //         fieldConfiguration.filter_type === "multi-select"
  //     );
  //     setFilterColumns(filters);
  //     const columnsData =
  //       fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.["data"]
  //         ?.filter(
  //           (fieldConfiguration: any) => fieldConfiguration.is_show_manage_table
  //         )
  //         ?.map((item: any, index: number) => ({
  //           title: item.display_name,
  //           dataIndex: item.original_name,
  //           key: item.original_name,
  //           width: item.width ?? 150,
  //           align: "left",
  //           ellipsis: true,
  //           sorter: true,
  //           sortDirections: ["ascend", "descend", null],
  //           render: (text: string, record: any, index: number) => (
  //             <div
  //               className={`table-avatar d-flex ${
  //                 item.display_field_type === "email-with-icon" ||
  //                 item.display_field_type === "phone-with-icon"
  //                   ? "justify-content-center"
  //                   : "justify-content-start"
  //               } align-items-center`}
  //               key={index}
  //             >
  //               {record?.[item.original_name] ? (
  //                 item.display_field_type === "email-with-icon" ? (
  //                   <OverlayTrigger
  //                     placement="bottom"
  //                     overlay={
  //                       <Tooltip
  //                         id="refresh-tooltip"
  //                         style={{ position: "fixed" }}
  //                       >
  //                         {record?.[item.original_name]}
  //                       </Tooltip>
  //                     }
  //                   >
  //                     <div className="mx-2">
  //                       <i className={"ti-email"}></i>
  //                     </div>
  //                   </OverlayTrigger>
  //                 ) : item.display_field_type === "phone-with-icon" ? (
  //                   <OverlayTrigger
  //                     placement="bottom"
  //                     overlay={
  //                       <Tooltip
  //                         id="refresh-tooltip"
  //                         style={{ position: "fixed" }}
  //                       >
  //                         {record?.[item.original_name]}
  //                       </Tooltip>
  //                     }
  //                   >
  //                     <div className="mx-2">
  //                       <i className={"ti-headphone"}></i>
  //                     </div>
  //                   </OverlayTrigger>
  //                 ) : item.display_field_type === "datetime" ||
  //                   item.display_field_type === "date" ? (
  //                   <OverlayTrigger
  //                     placement="bottom"
  //                     overlay={
  //                       <Tooltip
  //                         id="refresh-tooltip"
  //                         style={{ position: "fixed" }}
  //                       >
  //                         {displayFieldDatetimeValue(
  //                           record?.[item.original_name],
  //                           item.display_field_type
  //                         )}
  //                       </Tooltip>
  //                     }
  //                   >
  //                     <span
  //                       className={`profile-split cursor-pointer`}
  //                       style={{
  //                         cursor: "pointer",
  //                         wordBreak: "keep-all",
  //                         overflow: "hidden",
  //                         textOverflow: "ellipsis",
  //                       }}
  //                     >
  //                       {displayFieldDatetimeValue(
  //                         record?.[item.original_name],
  //                         item.display_field_type
  //                       )}
  //                     </span>
  //                   </OverlayTrigger>
  //                 ) : (
  //                   <OverlayTrigger
  //                     placement="bottom"
  //                     overlay={
  //                       <Tooltip
  //                         id="refresh-tooltip"
  //                         style={{ position: "fixed" }}
  //                       >
  //                         {record?.[item.original_name]}
  //                       </Tooltip>
  //                     }
  //                   >
  //                     <span
  //                       className={`profile-split cursor-pointer ${
  //                         item.original_name === "contact_id"
  //                           ? "text-decoration-underline"
  //                           : ""
  //                       }`}
  //                       style={{
  //                         cursor: "pointer",
  //                         wordBreak: "keep-all",
  //                         overflow: "hidden",
  //                         textOverflow: "ellipsis",
  //                       }}
  //                       onClick={() =>
  //                         item.original_name === "contact_id"
  //                           ? handleRowClick(record)
  //                           : null
  //                       }
  //                     >
  //                       {record?.[item.original_name]}
  //                     </span>
  //                   </OverlayTrigger>
  //                 )
  //               ) : null}
  //             </div>
  //           ),
  //           ...(index === 0 || index === 1
  //             ? {
  //                 fixed: "left",
  //               }
  //             : {}),
  //         })) || [];
  //     setColumns([
  //       {
  //         title: "Duplicate",
  //         dataIndex: "duplications",
  //         width: 100,
  //         render: (text: string, record: any, index: number) => (
  //           <div
  //             key={index}
  //             className="d-flex align-items-center justify-content-center gap-1"
  //           >
  //             {record?.duplicates?.["False"] ? (
  //               <OverlayTrigger
  //                 placement="bottom"
  //                 overlay={
  //                   <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
  //                     There're {record?.duplicates?.["False"]}{" "}
  //                     duplicated records.
  //                   </Tooltip>
  //                 }
  //               >
  //                 <span className="badge badge-pill badge-success">D</span>
  //               </OverlayTrigger>
  //             ) : null}
  //             {record?.duplicates?.["True"] ? (
  //               <OverlayTrigger
  //                 placement="bottom"
  //                 overlay={
  //                   <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
  //                     There're {record?.duplicates?.["True"]} merged
  //                     records.
  //                   </Tooltip>
  //                 }
  //               >
  //                 <span className="badge badge-pill badge-danger">M</span>
  //               </OverlayTrigger>
  //             ) : null}
  //           </div>
  //         ),
  //       },
  //       ...columnsData,
  //       {
  //         title: "Actions",
  //         dataIndex: "actions",
  //         align: "right",
  //         render: (text: string, record: any, index: number) => (
  //           <div className="dropdown table-action" key={index}>
  //             <Link
  //               to="#"
  //               className="action-icon"
  //               data-bs-toggle="dropdown"
  //               aria-expanded="true"
  //             >
  //               <i className="fa fa-ellipsis-v"></i>
  //             </Link>
  //             <div
  //               className="dropdown-menu dropdown-menu-right"
  //               style={{
  //                 position: "absolute",
  //                 inset: "0px auto auto 0px",
  //                 margin: "0px",
  //                 transform: "translate3d(-99.3333px, 35.3333px, 0px)",
  //               }}
  //               data-popper-placement="bottom-start"
  //             >
  //               <Link
  //                 className="dropdown-item edit-popup"
  //                 to="#"
  //                 onClick={() => {
  //                   dispatch(
  //                     setEditingContact({
  //                       editingData: record,
  //                     })
  //                   );
  //                   dispatch(setActiveModal({ modalId: "add_contact" }));
  //                 }}
  //               >
  //                 <i className="ti ti-edit text-blue"></i> Edit
  //               </Link>
  //               <Link
  //                 className="dropdown-item"
  //                 to="#"
  //                 onClick={() => dispatch(setRemoveContact(record.contact_id))}
  //               >
  //                 <i className="ti ti-trash text-danger"></i> Delete
  //               </Link>
  //             </div>
  //           </div>
  //         ),
  //       },
  //     ]);
  //   }
  // }, [JSON.stringify(fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"])]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* seagment */}
              <div className="contact-head">
                <div className="row align-items-center"></div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Seagments
                      {seagmentsPaginationData ? (
                        <span className="count-title">
                          {seagmentsPaginationData?.total}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          debouncedFilterSort({
                            filters: filterSearchChecked,
                            operator: filterOperator,
                            term: searchTerm,
                            order: order,
                            currentPage: pagination.current || 1,
                            pageSize: pagination.pageSize || 50,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="offset-md-5 col-md-7 offset-sm-4 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <div className="icon-form flex-grow-1">
                              <span className="form-icon">
                                <i className="ti ti-search" />
                              </span>
                              <SearchInput
                                className="form-control"
                                placeholder={`Search Seagment`}
                                onDebounce={(value: string) => {
                                  onSearch(value);
                                }}
                              />
                            </div>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => {
                                dispatch(
                                  setEditingSeagment({
                                    editingData: {
                                      seagment_id: "add",
                                    },
                                  })
                                );
                                dispatch(
                                  setActiveModal({ modalId: "add_seagment" })
                                );
                              }}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              New Seagment
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Seagment List */}
                {columns && columns.length ? (
                  <>
                    <div className="table-responsive custom-table">
                      <Table
                        dataSource={seagmentsData}
                        columns={columns}
                        loading={seagmentsDataLoading}
                        onSorterHandler={onSorterHandler}
                        pagination={{
                          position: ["bottomRight"],
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                          total: pagination.total,
                          onChange: (page, pageSize) => {
                            setPagination({
                              ...pagination,
                              current: page,
                              pageSize,
                            });
                          },
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                          locale: {
                            items_per_page: "records / page",
                          },
                        }}
                        onPaginationHandler={onPaginationHandler}
                        stickyHeader={true}
                        height={"calc(100vh - 270px)"}
                      />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="datatable-length" />
                      </div>
                      <div className="col-md-6">
                        <div className="datatable-paginate" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>No Columns</div>
                )}

                {/* /Seagment List */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSeagments;
