import { createSlice } from "@reduxjs/toolkit";
import {
  createCompany,
  editCompany,
  getCompany,
  getCompanies,
  deleteCompany,
  filterSortCompanies,
} from "./thunk";
import { TablePaginationConfig } from "antd";

const initialState: {
  companies: {
    loading: boolean;
    list: any[];
    pagination: TablePaginationConfig;
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    view: {
      data: any;
      loading: boolean;
    };
    select: {
      isOpen: boolean;
      data: any;
    };
  };
} = {
  companies: {
    loading: false,
    list: [],
    edit: {
      data: null,
      loading: false,
    },
    pagination: {
      total: 0,
      current: 1,
    },
    delete: {
      data: null,
    },
    view: {
      data: null,
      loading: false,
    },
    select: {
      isOpen: false,
      data: null
    },
  },
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // Company Actions
    setEditingCompany: (state, action) => {
      state.companies = {
        ...(state.companies || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingCompanyClose: (state) => {
      state.companies = {
        ...(state.companies || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveCompany: (state, action) => {
      state.companies = {
        ...(state.companies || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveCompanyClose: (state) => {
      state.companies = {
        ...(state.companies || {}),
        delete: {
          data: null,
        },
      };
    },
    setCompanyView: (state, action) => {
      state.companies = {
        ...(state.companies || {}),
        view: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setCompanyViewClose: (state) => {
      state.companies = {
        ...(state.companies || {}),
        view: {
          data: null,
          loading: false,
        },
      };
    },
    setSelectCompany: (state, action) => {
      state.companies = {
        ...(state.companies || {}),
        select: {
          isOpen: true,
          data: action.payload,
        },
      };
    },
    setSelectCompanyClose: (state) => {
      state.companies = {
        ...(state.companies || {}),
        select: {
          isOpen: false,
          data: null
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Company Actions
      .addCase(createCompany.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          edit: {
            ...(state.companies.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        const companyData: any = action.payload;
        state.companies = {
          ...(state.companies || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: [...state.companies.list, companyData],
        };
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          edit: {
            ...(state.companies.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editCompany.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          edit: {
            ...(state.companies.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        const companyData: any = action.payload;
        const oldCompanyList = [...(state?.companies?.list || [])];
        const currentCompanyIndex = oldCompanyList?.findIndex(
          (item) => item?.company_id === companyData?.company_id
        );

        let newCompanyList = [];
        if (currentCompanyIndex > -1) {
          newCompanyList = [...oldCompanyList];
          newCompanyList.splice(currentCompanyIndex, 1, companyData);
        } else {
          newCompanyList = [...(state.companies.list || []), companyData];
        }
        state.companies = {
          ...(state.companies || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: [...newCompanyList],
        };
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          edit: {
            ...(state.companies.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(getCompany.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          view: {
            ...(state.companies.view || {}),
            loading: state.companies.view.loading,
          },
          loading: true,
        };
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        const companyData = action.payload;
        const oldCompanyList = [...(state?.companies?.list || [])];
        const currentCompanyIndex = oldCompanyList?.findIndex(
          (item) => item?.company_id === companyData?.company_id
        );

        let newCompanyList = [];
        if (currentCompanyIndex > -1) {
          newCompanyList = [...oldCompanyList];
          newCompanyList.splice(currentCompanyIndex, 1, companyData);
        } else {
          newCompanyList = [...(state.companies.list || []), companyData];
        }
        state.companies = {
          ...(state.companies || {}),
          loading: false,
          view: {
            data: {
              visitingCompanyData: action.payload
            },
            loading: state.companies.view.loading,
          },
          list: [...newCompanyList],
        };
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          view: {
            data: null,
            loading: state.companies.view.loading,
          },
          loading: false,
        };
      })
      .addCase(getCompanies.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: true,
          pagination: {
            total: 0,
            current: 1,
          },
        };
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.companies = {
          ...(state.companies || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: false,
        };
      })
      .addCase(filterSortCompanies.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: true,
        };
      })
      .addCase(filterSortCompanies.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.companies = {
          ...(state.companies || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(filterSortCompanies.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: false,
        };
      })
      .addCase(deleteCompany.pending, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: true,
        };
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        const companyId = action.payload;
        state.companies = {
          ...(state.companies || {}),
          loading: false,
          list: (state.companies.list || []).filter(
            (item: any) => item.company_id !== companyId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.companies = {
          ...(state.companies || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingCompany,
  setEditingCompanyClose,
  setRemoveCompany,
  setRemoveCompanyClose,
  setCompanyView,
  setCompanyViewClose,
  setSelectCompany,
  setSelectCompanyClose,
} = companySlice.actions;

export default companySlice.reducer;
