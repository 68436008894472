import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setEditingLeadClose } from "../../data/redux/leads/leads";
import { createLead, editLead } from "../../data/redux/leads/thunk";
import { getErrorMessage } from "../../../utils/helper";
import {
  getInitialValueByFieldType,
  getValidationSchemaByFieldType,
} from "../contacts/utils/helper";
import BaseModal from "../common/baseModal";
import { closeModal } from "../../data/redux/modals/modals";

const AddLeadModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.lead?.leads?.edit?.loading
  );
  const modalData = useSelector((state: any) => state?.lead?.leads?.edit?.data);
  const { editingData, campaignId, pageRoot, modelCategory, onSubmitFinish } =
    modalData || {};

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const [requiredFields, setRequiredFields] = useState([]);
  const [showFields, setShowFields] = useState([]);

  useEffect(() => {
    const pageFieldAdminData =
      fieldsAdminData?.[pageRoot ?? "contact"]?.["Lead"]?.[modelCategory]?.[
        "data"
      ];
    if (pageFieldAdminData?.length) {
      const isShownFields = pageFieldAdminData.filter(
        (field: any) =>
          field.is_show_popup &&
          field.field_type !== "contact" &&
          field.original_name !== "lead_id"
      );
      setShowFields(isShownFields);

      const result = isShownFields.filter(
        (field: any) => field.is_required_popup
      );
      setRequiredFields(result);
    }
  }, [fieldsAdminData]);

  const initialValues = useMemo(() => {
    if (showFields?.length) {
      const result = showFields.reduce(
        (initialData: any, currentField: any) => {
          const { original_name, display_field_type, field_type } =
            currentField;

          initialData[original_name] = editingData
            ? field_type === "custom"
              ? editingData?.["leads_custom_values"]?.[0]?.[
                  "lead_custom_field_value"
                ]?.[original_name]
              : editingData[original_name]
            : getInitialValueByFieldType(display_field_type);
          return initialData;
        },
        {}
      );
      return result;
    }
    return {};
  }, [JSON.stringify(showFields), JSON.stringify(editingData)]);

  const validationSchema = useMemo(() => {
    if (requiredFields?.length) {
      const result = requiredFields.reduce(
        (initialSchema: any, currentField: any) => {
          const { original_name, display_name, display_field_type } =
            currentField;
          initialSchema[original_name] = getValidationSchemaByFieldType(
            display_name,
            display_field_type
          );
          return initialSchema;
        },
        {}
      );

      return result;
    }
    return {};
  }, [JSON.stringify(requiredFields)]);

  const customFields = useMemo(() => {
    if (showFields?.length) {
      const result = showFields.reduce((fieldsData: any, currentField: any) => {
        const { field_type, original_name } = currentField;
        if (field_type === "custom") {
          fieldsData.push(original_name);
        }
        return fieldsData;
      }, []);
      return result;
    }
    return [];
  }, [JSON.stringify(showFields)]);

  const getDateValue = (date: any) => {
    try {
      if (date) {
        return new Date(date);
      }
      return null;
    } catch (_) {
      return null;
    }
  };

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setEditingLeadClose());
        dispatch(closeModal("add_lead"));
      }}
      title={editingData?.lead_id === "add" ? "Add New Lead" : "Edit Lead"}
      className="sidebar-popup"
      modalId={"add_lead"}
    >
      <div className="toggle-body">
        {!showFields?.length ? (
          <>No show fields</>
        ) : (
          <div className="pro-create">
            <Formik
              initialValues={{ ...initialValues, submit: null }}
              validationSchema={Yup.object().shape(validationSchema)}
              enableReinitialize={true}
              onSubmit={async (values, { resetForm, setErrors }) => {
                try {
                  let newLead: any = values;

                  // get custom fields value

                  const customFieldsValue = customFields.reduce(
                    (fieldsValue: any, currentField: string) => {
                      const result = {
                        ...(fieldsValue || {}),
                        [currentField]: newLead?.[currentField],
                      };
                      return result;
                    },
                    {}
                  );

                  let res: any;
                  const payload = {
                    ...newLead,
                    campaign: campaignId,
                    custom_fields: customFieldsValue,
                    contact: editingData?.contact_id,
                  };
                  if (editingData?.lead_id === "add") {
                    res = await dispatch(createLead(payload));
                  } else {
                    res = await dispatch(
                      editLead({
                        lead_id: editingData.lead_id,
                        data: payload,
                      })
                    );
                  }

                  if (res && res?.error) {
                    let errorData;
                    try {
                      errorData = JSON.parse(res?.error?.message);
                      setErrors(errorData);
                    } catch (_) {
                      errorData = "";
                      setErrors({ submit: getErrorMessage(errorData) });
                    }
                  } else {
                    resetForm();
                    onSubmitFinish &&
                      onSubmitFinish(
                        editingData?.company_id ?? editingData?.contact_id
                      );
                    dispatch(setEditingLeadClose());
                    dispatch(closeModal("add_lead"));
                  }
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {/* Lead Info */}

                  <div className="row gy-2">
                    {showFields.map(
                      ({
                        display_name,
                        display_field_type,
                        original_name,
                        dropdown_options,
                        is_required_popup
                      }) => (
                        <div className="col-md-12" key={original_name}>
                          <div className="form-wrap">
                            <label className="col-form-label">
                              {display_name}&nbsp;
                              {is_required_popup && (
                                <span style={{ color: 'red' }}>*</span>
                              )}
                            </label>
                            {display_field_type === "select" ? (
                              <Select
                                defaultValue={[]}
                                name={original_name}
                                options={dropdown_options || []}
                                styles={{
                                  menu: (base) => ({ ...base, zIndex: 10 }),
                                }}
                                onBlur={handleBlur}
                                value={
                                  values?.[original_name]
                                    ? {
                                        label: values?.[original_name],
                                        value: values?.[original_name],
                                      }
                                    : null
                                }
                                onChange={(option: any) => {
                                  setFieldValue(original_name, option.value);
                                }}
                              />
                            ) : display_field_type === "boolean" ? (
                              <div className="form-switch form-check-md">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  checked={values?.[original_name]}
                                  name={original_name}
                                  onBlur={handleBlur}
                                  onChange={(event: any) => {
                                    setFieldValue(
                                      original_name,
                                      event.target.checked
                                    );
                                  }}
                                />
                              </div>
                            ) : display_field_type === "text" ? (
                              <textarea
                                name={original_name}
                                className="form-control"
                                value={values?.[original_name]}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            ) : display_field_type === "datetime" ||
                              display_field_type === "date" ? (
                              <div className="icon-form">
                                <span className="form-icon">
                                  <i className="ti ti-calendar-check" />
                                </span>
                                <DatePicker
                                  showTimeSelect={
                                    display_field_type === "datetime"
                                  }
                                  className="form-control datetimepicker deals-details w-100"
                                  name={original_name}
                                  value={values?.[original_name]}
                                  selected={getDateValue(
                                    values?.[original_name]
                                  )}
                                  onChange={(val) =>
                                    setFieldValue(original_name, val)
                                  }
                                  dateFormat={
                                    display_field_type === "datetime"
                                      ? "dd-MM-yyyy h:mm aa"
                                      : "dd-MM-yyyy"
                                  }
                                  popperClassName="datepicker-popper"
                                />
                              </div>
                            ) : (
                              <input
                                type={
                                  display_field_type === "char"
                                    ? "text"
                                    : (display_field_type as string)?.includes(
                                        "email"
                                      )
                                    ? "email"
                                    : (display_field_type as string)?.includes(
                                        "phone"
                                      )
                                    ? "tel"
                                    : "number"
                                }
                                name={original_name}
                                className="form-control"
                                value={values?.[original_name]}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            )}
                          </div>
                          {touched?.[original_name] &&
                            errors?.[original_name] && (
                              <Alert
                                message={errors?.[original_name] as string}
                                type="error"
                              />
                            )}
                        </div>
                      )
                    )}
                  </div>
                  <div className="row gy-2">
                    <div className="col-md-12">
                      <div className="form-wrap">
                        {errors.submit && (
                          <Alert
                            message={errors.submit as string}
                            type="error"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="submit-button text-end">
                    <Link
                      to="#"
                      className="btn btn-light sidebar-close"
                      onClick={() => {
                        dispatch(setEditingLeadClose());
                        dispatch(closeModal('add_lead'));
                      }}
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      {editingData?.lead_id === "add" ? "Create" : "Edit"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default AddLeadModal;
