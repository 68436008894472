import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import {
  setContactViewClose,
  setEditingContact,
} from "../../data/redux/contacts/contacts";
import {
  demergeDuplicates,
  getContact,
  getContactDuplicates,
  mergeDuplicates,
} from "../../data/redux/contacts/thunk";
import MultiSelectBox from "../../common/multiSelectBox";
import { customArrayToString, getDistinctFields } from "./utils/helper";
import { Empty, Flex } from "antd";
import BaseModal from "../common/baseModal";
import {
  setCompanyView,
  setEditingCompany,
  setSelectCompany,
} from "../../data/redux/companies/companies";
import {
  setEditingLead,
  setLeadView,
  setSelectLead,
} from "../../data/redux/leads/leads";
import { ToastContainer, toast } from "react-toastify";
import ContactService from "../../../services/contacts";
import { closeModal, setActiveModal } from "../../data/redux/modals/modals";
import { displayFieldDatetimeValue, isValidUrl } from "../../../utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const ContactDetailModal = () => {
  const dispatch = useDispatch<any>();

  const modalToggle = useSelector(
    (state: any) => state?.contact?.contacts?.view?.loading
  );
  const modalData = useSelector(
    (state: any) => state?.contact?.contacts?.view?.data
  );
  const { visitingContactData, onMergeHandler } = modalData || {};

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const contactInternalDataFields = (
    fieldsAdminData?.["contact"]?.["ContactNew"]?.["main"]?.["data"] || []
  ).filter((field: any) => field?.is_show_popup);

  const [activeTab, setActiveTab] = useState("info");
  const [duplicationActiveTab, setDuplicationActiveTab] = useState("merge");

  const [mergedDuplicatesOptions, setMergedDuplicatesOptions] = useState<any[]>(
    []
  );
  const [pendingDuplicatesOptions, setPendingDuplicatesOptions] = useState<
    any[]
  >([]);

  const [selectedMergedOptions, setSelectedMergedOptions] = useState<any[]>([]);
  const [selectedPendingOptions, setSelectedPendingOptions] = useState<any[]>(
    []
  );
  const [selectedPrimaryContact, setSelectedPrimaryContact] =
    useState<string>("");

  const [editedContacts, setEditedContacts] = useState<{ [key: string]: any }>(
    {}
  );

  const [isTableMaximized, setIsTableMaximized] = useState(false);

  const getDateValue = (date: any) => {
    try {
      if (date) {
        return new Date(date);
      }
      return null;
    } catch (_) {
      return null;
    }
  };

  // Helper function to handle field edits
  const handleFieldEdit = (
    contactId: string,
    fieldName: string,
    value: any
  ) => {
    setEditedContacts((prev) => ({
      ...prev,
      [contactId]: {
        ...(prev[contactId] || {}),
        [fieldName]: value,
      },
    }));
  };

  const retrieveContactDuplicates = async (contactId: any) => {
    const res = await dispatch(getContactDuplicates(contactId));
    const data = res?.payload?.duplicates || {};
    setPendingDuplicatesOptions(
      (data?.["False"] ?? []).map((duplicate: any) => {
        return {
          value: duplicate.contact_id,
          label: duplicate["full_name"],
          additionalInfo: duplicate,
        };
      })
    );
    setSelectedPendingOptions([
      visitingContactData?.contact_id,
      ...(data?.["False"] ?? []).map((duplicate: any) => duplicate.contact_id),
    ]);
    setMergedDuplicatesOptions(
      (data?.["True"] ?? []).map((duplicate: any) => {
        const additionalInfo = customArrayToString(
          getDistinctEmailPhoneFields(duplicate)
        );
        return {
          value: duplicate.contact_id,
          label: duplicate["full_name"],
          additionalInfo: additionalInfo,
        };
      })
    );
  };

  const getDistinctEmailPhoneFields = (contact: any) => {
    const uniqueEmails = getDistinctFields(contact, [
      "email",
      "email1",
      "email2",
      "email3",
    ]);
    const uniquePhones = getDistinctFields(contact, [
      "phone",
      "phone1",
      "phone2",
      "phone3",
    ]);

    const uniqueFields = [...uniqueEmails, ...uniquePhones];

    return uniqueFields;
  };

  const renderDuplicatesTable = () => {
    const contacts = [
      visitingContactData,
      ...pendingDuplicatesOptions.map((opt) => opt.additionalInfo),
    ];
    const fields = contactInternalDataFields.filter(
      (f: any) => f?.is_show_popup
    );

    // Handler for contact selection
    const handleContactSelection = (contactId: string, checked: boolean) => {
      setSelectedPendingOptions((prev) => {
        const newSelection = checked
          ? [...prev, contactId]
          : prev.filter((id) => id !== contactId);

        // If primary contact is unselected, clear it
        if (!checked && contactId === selectedPrimaryContact) {
          setSelectedPrimaryContact("");
        }
        return newSelection;
      });
    };

    const renderEditableField = (contact: any, field: any) => {
      // Skip rendering editable field if it's contact_id
      if (
        field.original_name === "contact_id" ||
        field.original_name === "created_at" ||
        field.original_name === "updated_at"
      ) {
        return (
          <span>
            {displayFieldDatetimeValue(contact?.[field.original_name])}
          </span>
        );
      }

      const currentValue =
        editedContacts[contact?.contact_id]?.[field.original_name] ??
        contact?.[field?.original_name];

      switch (field.display_field_type) {
        case "boolean":
          return (
            <input
              type="checkbox"
              checked={currentValue}
              onChange={(e) =>
                handleFieldEdit(
                  contact?.contact_id,
                  field.original_name,
                  e.target.checked
                )
              }
            />
          );
        case "datetime":
        case "date":
          return (
            <div className="icon-form">
              <span className="form-icon">
                <i className="ti ti-calendar-check" />
              </span>
              <DatePicker
                showTimeSelect={field?.display_field_type === "datetime"}
                className="form-control datetimepicker deals-details w-100"
                name={field.original_name}
                value={currentValue || ""}
                selected={getDateValue(currentValue)}
                onChange={(val) =>
                  handleFieldEdit(contact?.contact_id, field.original_name, val)
                }
                dateFormat={
                  field?.display_field_type === "datetime"
                    ? "dd-MM-yyyy h:mm aa"
                    : "dd-MM-yyyy"
                }
                popperClassName="datepicker-popper"
              />
            </div>
          );
        case "select":
          return (
            <Select
              defaultValue={[]}
              name={field?.original_name}
              options={field?.dropdown_options || []}
              styles={{
                menu: (base) => ({ ...base, zIndex: 10 }),
              }}
              value={
                currentValue
                  ? {
                      label: currentValue,
                      value: currentValue,
                    }
                  : null
              }
              onChange={(option: any) => {
                handleFieldEdit(
                  contact?.contact_id,
                  field.original_name,
                  option.value
                );
              }}
            />
          );
        default:
          return (
            <input
              type="text"
              value={currentValue || ""}
              onChange={(e) =>
                handleFieldEdit(
                  contact?.contact_id,
                  field.original_name,
                  e.target.value
                )
              }
              className="form-control form-control-sm"
            />
          );
      }
    };

    return (
      <div className="position-relative max-height-inherit">
        <div
          className={`table-responsive ${
            isTableMaximized ? "maximized-table" : ""
          }`}
        >
          <div className="d-flex justify-content-end mt-2 mb-2">
            <button
              className="btn btn-light"
              onClick={() => setIsTableMaximized(!isTableMaximized)}
            >
              <i
                className={`ti ti-${
                  isTableMaximized ? "minimize" : "maximize"
                }`}
              ></i>
            </button>
          </div>
          <table className="table table-bordered sticky-table">
            <thead>
              {/* Primary Selection Header */}
              <tr className="sticky-row first-row">
                <th className="sticky-col"></th>
                {contacts.map((contact, idx) => (
                  <th key={idx} className="text-center">
                    {selectedPendingOptions.includes(contact?.contact_id) && (
                      <button
                        className={`btn btn-sm ${
                          selectedPrimaryContact === contact?.contact_id
                            ? "btn-success"
                            : "btn-outline-primary"
                        } w-100`}
                        onClick={() =>
                          setSelectedPrimaryContact(contact?.contact_id)
                        }
                      >
                        {selectedPrimaryContact === contact?.contact_id ? (
                          <>
                            <i className="ti ti-check me-1"></i>Primary
                          </>
                        ) : (
                          "Select Primary"
                        )}
                      </button>
                    )}
                  </th>
                ))}
              </tr>

              <tr className="sticky-row second-row">
                <th className="sticky-col">Fields</th>
                {contacts.map((contact, idx) => (
                  <th key={idx}>
                    <div className="d-flex align-items-center gap-2">
                      <input
                        type="checkbox"
                        checked={selectedPendingOptions.includes(
                          contact?.contact_id
                        )}
                        onChange={(e) =>
                          handleContactSelection(
                            contact?.contact_id,
                            e.target.checked
                          )
                        }
                      />
                      <span>Contact {idx + 1}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Separate readonly contact_id row */}
              <tr className="sticky-row third-row">
                <td className="sticky-col">Contact ID</td>
                {contacts.map((contact, idx) => (
                  <td key={idx} className="text-muted">
                    {contact?.contact_id}
                  </td>
                ))}
              </tr>
              {/* Render all other editable fields */}
              {fields
                .filter((field: any) => field?.original_name !== "contact_id")
                .map((field: any) => (
                  <tr key={field.original_name}>
                    <td className="sticky-col">{field.display_name}</td>
                    {contacts.map((contact, idx) => (
                      <td key={idx}>{renderEditableField(contact, field)}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const manageMergeDuplicates = async (isMerge: boolean = false) => {
    if (isMerge && !selectedPrimaryContact) {
      toast.error("Please select a primary contact for merging");
      return;
    }

    if (isMerge) {
      const payload = {
        primary: selectedPrimaryContact,
        merging: selectedPendingOptions.filter(
          (id) => id !== selectedPrimaryContact
        ),
        contactData: editedContacts,
      };

      await dispatch(mergeDuplicates(payload));
      await dispatch(getContact(visitingContactData?.contact_id));
    } else {
      const payload = {
        demerging: selectedMergedOptions.map((option: any) => option.value),
      };
      await dispatch(demergeDuplicates(payload));
    }

    setSelectedPrimaryContact("");
    setSelectedMergedOptions([]);
    setEditedContacts({});
    setSelectedPendingOptions([]);
    retrieveContactDuplicates(visitingContactData.contact_id);
    onMergeHandler && onMergeHandler();
  };

  useEffect(() => {
    if (visitingContactData?.contact_id) {
      dispatch(getContact(visitingContactData.contact_id));
      retrieveContactDuplicates(visitingContactData.contact_id);
    }
  }, [visitingContactData?.contact_id]);

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setContactViewClose());
        dispatch(closeModal("detail_contact"));
      }}
      title="Contact Detail"
      className="sidebar-popup"
      modalId={"detail_contact"}
    >
      <div className="pro-create">
        <div className="row">
          <div className="col-xl-12">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link
                    to="#"
                    className={activeTab === "info" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("info");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Info
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className={activeTab === "leads" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("leads");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Leads
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={activeTab === "companies" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("companies");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Companies
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={activeTab === "duplicates" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("duplicates");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Duplicates
                  </Link>
                </li>
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                <div
                  className={`tab-pane ${
                    activeTab === "info" ? "active show" : ""
                  }`}
                  id="info"
                >
                  <div className="view-header">
                    <h4>Contact Info</h4>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch(
                          setEditingContact({
                            editingData: visitingContactData,
                            pageRoot: "contact",
                            modelCategory: "main",
                            onSubmitFinish: () => {
                              dispatch(
                                getContact(visitingContactData?.contact_id)
                              );
                            },
                          })
                        );
                        dispatch(setActiveModal({ modalId: "add_contact" }));
                      }}
                    >
                      <i className="ti ti-edit mr-2"></i> Edit
                    </button>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      {(contactInternalDataFields || []).map((field: any) => (
                        <li key={field.original_name} className="detail-item">
                          <span className="other-title h6">
                            {field.display_name}
                          </span>
                          <span>
                            {field.display_field_type === "boolean" ? (
                              <Flex gap="4px 0" className="align-items-center">
                                <div className="form-switch form-check-md">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={
                                      visitingContactData?.[
                                        field.original_name
                                      ] || false
                                    }
                                    disabled
                                  />
                                </div>
                              </Flex>
                            ) : field.display_field_type === "datetime" ||
                              field.display_field_type === "date" ? (
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {displayFieldDatetimeValue(
                                  visitingContactData?.[field.original_name],
                                  field.display_field_type
                                )}
                              </div>
                            ) : (
                              <>{visitingContactData?.[field.original_name]}</>
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Basic */}
                <div
                  className={`tab-pane ${
                    activeTab === "leads" ? "active show" : ""
                  }`}
                  id="leads"
                >
                  <div className="view-header">
                    <h4>Leads</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Lead
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectLead({
                                  onSubmitFinish: async (lead_id: string) => {
                                    try {
                                      const response =
                                        await ContactService.addLeadtoContact({
                                          contact_id:
                                            visitingContactData?.contact_id,
                                          lead_id: lead_id,
                                        });
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getContact(
                                          visitingContactData?.contact_id
                                        )
                                      );
                                      toast.success("Lead Added Successfully", {
                                        position: "top-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message || "Failed to Add Lead",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({
                                  modalId: "select_lead",
                                })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Lead
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingLead({
                                  editingData: {
                                    lead_id: "add",
                                    contact_id: visitingContactData?.contact_id,
                                  },
                                  pageRoot: "lead",
                                  modelCategory: "main",
                                  onSubmitFinish: (contact_id: string) => {
                                    dispatch(getContact(contact_id));
                                  },
                                })
                              );
                              dispatch(setActiveModal({ modalId: "add_lead" }));
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Lead
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingContactData?.contact_leads?.length ? (
                          (visitingContactData?.contact_leads || []).map(
                            (lead: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={lead.lead_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-lead-${lead.lead_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {lead.lead_id}
                                      </span>
                                      <span className="me-3">
                                        {lead.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setLeadView({
                                                visitingLeadData: lead,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_lead",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            try {
                                              const response =
                                                await ContactService.addLeadtoContact(
                                                  {
                                                    contact_id:
                                                      visitingContactData?.contact_id,
                                                    lead_id: lead?.lead_id,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getContact(
                                                  visitingContactData?.contact_id
                                                )
                                              );
                                              toast.success(
                                                "Lead Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Lead",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-lead-${lead.lead_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.["lead"]?.["Lead"]?.[
                                        "main"
                                      ]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              <span>
                                                {lead?.[field.original_name] ? (
                                                  isValidUrl(
                                                    lead[field.original_name]
                                                  ) ? (
                                                    <div
                                                      className="text-truncate"
                                                      style={{
                                                        maxWidth: "250px",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          lead[
                                                            field.original_name
                                                          ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {
                                                          lead[
                                                            field.original_name
                                                          ]
                                                        }
                                                      </a>
                                                    </div>
                                                  ) : typeof lead?.[
                                                      field.original_name
                                                    ] === "object" ? (
                                                    Object.entries(
                                                      lead?.[
                                                        field.original_name
                                                      ] || {}
                                                    ).map(
                                                      ([key, value]: [
                                                        string,
                                                        any
                                                      ]) =>
                                                        value ? (
                                                          <div>
                                                            <span>{key}</span>:
                                                            <span>{value}</span>
                                                          </div>
                                                        ) : null
                                                    )
                                                  ) : field.display_field_type ===
                                                      "datetime" ||
                                                    field.display_field_type ===
                                                      "date" ? (
                                                    displayFieldDatetimeValue(
                                                      lead?.[
                                                        field.original_name
                                                      ],
                                                      field.display_field_type
                                                    )
                                                  ) : (
                                                    lead?.[field.original_name]
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>

                <div
                  className={`tab-pane ${
                    activeTab === "companies" ? "active show" : ""
                  }`}
                  id="companies"
                >
                  <div className="view-header">
                    <h4>Companies</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Company
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectCompany({
                                  onSubmitFinish: async (
                                    company_id: string
                                  ) => {
                                    try {
                                      const response =
                                        await ContactService.addCompanytoContact(
                                          {
                                            contact_id:
                                              visitingContactData?.contact_id,
                                            company_id: company_id,
                                          }
                                        );
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getContact(
                                          visitingContactData?.contact_id
                                        )
                                      );
                                      toast.success(
                                        "Company Added Successfully",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message ||
                                          "Failed to Add Company",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "select_company" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Company
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingCompany({
                                  editingData: {
                                    company_id: "add",
                                    contact_id: visitingContactData?.contact_id,
                                  },
                                  onSubmitFinish: (contact_id: string) => {
                                    dispatch(getContact(contact_id));
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "add_company" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Company
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingContactData?.companies?.length ? (
                          (visitingContactData?.companies || []).map(
                            (company: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={company.company_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-company-${company.company_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {company.company_id}
                                      </span>
                                      <span className="me-3">
                                        {company.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setCompanyView({
                                                visitingCompanyData: company,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_company",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            try {
                                              const response =
                                                await ContactService.addCompanytoContact(
                                                  {
                                                    contact_id:
                                                      visitingContactData?.contact_id,
                                                    company_id:
                                                      company?.company_id,
                                                    is_add: false,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getContact(
                                                  visitingContactData?.contact_id
                                                )
                                              );
                                              toast.success(
                                                "Company Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Company",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-company-${company.company_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.["contact"]?.[
                                        "Company"
                                      ]?.["main"]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              {company?.[
                                                field.original_name
                                              ] ? (
                                                isValidUrl(
                                                  company[field.original_name]
                                                ) ? (
                                                  <div
                                                    className="text-truncate"
                                                    style={{
                                                      maxWidth: "250px",
                                                    }}
                                                  >
                                                    <a
                                                      href={
                                                        company[
                                                          field.original_name
                                                        ]
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {
                                                        company[
                                                          field.original_name
                                                        ]
                                                      }
                                                    </a>
                                                  </div>
                                                ) : typeof company?.[
                                                    field.original_name
                                                  ] === "object" ? (
                                                  Object.entries(
                                                    company?.[
                                                      field.original_name
                                                    ] || {}
                                                  ).map(
                                                    ([key, value]: [
                                                      string,
                                                      any
                                                    ]) =>
                                                      value ? (
                                                        <div>
                                                          <span>{key}</span>:
                                                          <span>{value}</span>
                                                        </div>
                                                      ) : null
                                                  )
                                                ) : field.display_field_type ===
                                                    "datetime" ||
                                                  field.display_field_type ===
                                                    "date" ? (
                                                  displayFieldDatetimeValue(
                                                    company?.[
                                                      field.original_name
                                                    ],
                                                    field.display_field_type
                                                  )
                                                ) : (
                                                  company?.[field.original_name]
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "duplicates" ? "active show" : ""
                  }`}
                  id="duplicates"
                >
                  <div className="view-header">
                    <h4>Duplicates</h4>
                  </div>
                  <div className="notes-activity">
                    <div className="contact-tab-wrap">
                      <ul className="contact-nav nav">
                        <li>
                          <Link
                            to="#"
                            className={
                              duplicationActiveTab === "merge" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setDuplicationActiveTab("merge");
                            }}
                          >
                            <i className="ti ti-notes" />
                            Merge
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="#"
                            className={
                              duplicationActiveTab === "demerge" ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setDuplicationActiveTab("demerge");
                            }}
                          >
                            <i className="ti ti-notes" />
                            De-Merge
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="contact-tab-view">
                      <div className="tab-content pt-0">
                        {/* Basic */}
                        {/* <div
                          className={`tab-pane ${
                            duplicationActiveTab === "merge"
                              ? "active show"
                              : ""
                          }`}
                          id="merge"
                        >
                          <div className="view-header">
                            <h4>Merge</h4>
                          </div>
                          <div className="notes-activity bottom-underline">
                            <MultiSelectBox
                              label="Select Items to merge"
                              selected={selectedPendingOptions}
                              options={pendingDuplicatesOptions}
                              onSelectOptions={(selected: any) => {
                                setSelectedPendingOptions(selected);
                              }}
                              onClearOptions={() => {}}
                            />
                          </div>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={async () => {
                                await manageMergeDuplicates(true);
                              }}
                            >
                              Merge
                            </button>
                          </div>
                        </div> */}

                        <div
                          className={`tab-pane ${
                            duplicationActiveTab === "merge"
                              ? "active show"
                              : ""
                          }`}
                          id="merge"
                        >
                          <div className="view-header">
                            <h4>Merge Contacts</h4>
                            <div className="text-muted">
                              Select a primary contact and review the fields to
                              merge
                            </div>
                          </div>
                          <div
                            className={`notes-activity bottom-underline ${
                              isTableMaximized ? "" : "duplicates-table"
                            }`}
                          >
                            {renderDuplicatesTable()}
                          </div>
                          <div className="d-flex flex-row-reverse gap-2 mt-3">
                            <button
                              className="btn btn-success"
                              type="button"
                              disabled={!selectedPrimaryContact}
                              onClick={() => manageMergeDuplicates(true)}
                            >
                              Merge Selected
                            </button>
                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setSelectedPrimaryContact("");
                                setSelectedMergedOptions([]);
                                setEditedContacts({});
                                setSelectedPendingOptions([]);

                                dispatch(setContactViewClose());
                                dispatch(closeModal("detail_contact"));
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>

                        <div
                          className={`tab-pane ${
                            duplicationActiveTab === "demerge"
                              ? "active show"
                              : ""
                          }`}
                          id="demerge"
                        >
                          <div className="view-header">
                            <h4>De-Merge</h4>
                          </div>
                          <div className="notes-activity bottom-underline">
                            <MultiSelectBox
                              label="Select Items to demerge"
                              selected={selectedMergedOptions}
                              options={mergedDuplicatesOptions}
                              onSelectOptions={(selected: any) => {
                                setSelectedMergedOptions(selected);
                              }}
                              onClearOptions={() => {}}
                            />
                          </div>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn btn-danger"
                              type="button"
                              disabled={!selectedMergedOptions?.length}
                              onClick={async () => {
                                await manageMergeDuplicates();
                              }}
                            >
                              DeMerge
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Contacts */}
              </div>
            </div>
            {/* /Tab Content */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </BaseModal>
  );
};

export default ContactDetailModal;
