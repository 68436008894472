import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  logout,
  getUsers,
  initAuth,
  createUser,
  editUser,
  deleteUser,
  getUser,
  getUserGroups,
  createUserGroup,
  getUserGroup,
  editUserGroup,
  deleteUserGroup,
  getPermissions,
} from "./thunk";
import { sortBy } from "lodash";

const initialState: {
  currentUser: {
    isLoggedIn: boolean;
    isInitialized: boolean;
    data: any;
    loading: boolean;
    token: any;
  };
  users: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    loading: boolean;
    data: any[];
  };
  groups: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    loading: boolean;
    data: any[];
  };
  permissions: {
    loading: boolean;
    data: any[];
  };
} = {
  currentUser: {
    isLoggedIn: false,
    isInitialized: false,
    data: null,
    loading: false,
    token: null,
  },
  users: {
    data: [],
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
    loading: false,
  },
  groups: {
    data: [],
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
    loading: false,
  },
  permissions: {
    loading: false,
    data: [],
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogout: () => {},
    // User Actions
    setEditingUser: (state, action) => {
      state.users = {
        ...(state.users || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingUserClose: (state) => {
      state.users = {
        ...(state.users || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveUser: (state, action) => {
      state.users = {
        ...(state.users || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveUserClose: (state) => {
      state.users = {
        ...(state.users || {}),
        delete: {
          data: null,
        },
      };
    },
    // User Group Actions
    setEditingUserGroup: (state, action) => {
      state.groups = {
        ...(state.groups || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingUserGroupClose: (state) => {
      state.groups = {
        ...(state.groups || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveUserGroup: (state, action) => {
      state.groups = {
        ...(state.groups || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveUserGroupClose: (state) => {
      state.groups = {
        ...(state.groups || {}),
        delete: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        state.currentUser = {
          isInitialized: true,
          isLoggedIn: false,
          loading: false,
          data: null,
          token: null,
        };
      })
      .addCase(login.pending, (state, action) => {
        state.currentUser = {
          ...(state.currentUser || {}),
          isLoggedIn: false,
          loading: true,
          data: null,
          token: null,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        const { access, user } = action.payload;
        state.currentUser = {
          isInitialized: true,
          isLoggedIn: true,
          loading: false,
          data: user,
          token: access,
        };
      })
      .addCase(login.rejected, (state, action) => {
        state.currentUser = {
          ...(state.currentUser || {}),
          isLoggedIn: false,
          loading: false,
          data: null,
          token: null,
        };
      })
      .addCase(initAuth.pending, (state, action) => {
        state.currentUser = {
          ...(state.currentUser || {}),
          isLoggedIn: false,
          data: null,
          loading: true,
        };
      })
      .addCase(initAuth.fulfilled, (state, action) => {
        state.currentUser = {
          ...(state.currentUser || {}),
          isInitialized: true,
          isLoggedIn: action.payload ? true : false,
          data: action.payload,
          loading: false,
          token: action.payload ? state?.currentUser?.token : null
        };
      })
      .addCase(initAuth.rejected, (state, action) => {
        state.currentUser = {
          ...(state.currentUser || {}),
          isLoggedIn: false,
          data: null,
          loading: false,
          token: null
        };
      })
      // User Actions
      .addCase(getUsers.pending, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: true,
        };
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: false,
          data: [],
        };
      })

      .addCase(getUser.pending, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: true,
        };
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const userData = action.payload;
        const existingData = (state.users.data || []).filter(
          (item: any) => item.id !== userData?.id
        );
        state.users = {
          ...(state.users || {}),
          loading: false,
          data: sortBy([...existingData, userData], "date_joined"),
        };
      })
      .addCase(getUser.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: false,
        };
      })
      .addCase(createUser.pending, (state, action) => {
        state.users = {
          ...(state.users || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.users = {
          ...(state.users || {}),
          edit: {
            data: null,
            loading: false,
          },
          data: sortBy([...(state.users.data || []), data], "date_joined"),
        };
      })
      .addCase(createUser.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(editUser.pending, (state, action) => {
        state.users = {
          ...(state.users || {}),
          edit: {
            ...(state.users.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editUser.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.users = {
          ...(state.users || {}),
          edit: {
            data: null,
            loading: false,
          },
          data: sortBy(
            [
              ...state.users.data?.filter(
                (item: any) => item.id !== payload.id
              ),
              payload,
            ],
            "date_joined"
          ),
        };
      })
      .addCase(editUser.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: true,
        };
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const userId = action.payload;
        state.users = {
          ...(state.users || {}),
          loading: false,
          data: (state.users.data || []).filter(
            (item: any) => item.id !== userId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: false,
        };
      })
      // User Group Actions
      .addCase(getUserGroups.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(getUserGroups.fulfilled, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getUserGroups.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          data: [],
        };
      })
      .addCase(getUserGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(getUserGroup.fulfilled, (state, action) => {
        const groupData = action.payload;
        const existingData = (state.groups.data || []).filter(
          (item: any) => item.id !== groupData?.id
        );
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          data: [...existingData, groupData],
        };
      })
      .addCase(getUserGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
        };
      })
      .addCase(createUserGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createUserGroup.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
          data: [...(state.groups.data || []), data],
        };
      })
      .addCase(createUserGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(editUserGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            ...(state.groups.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editUserGroup.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
          data: [
            ...state.groups.data?.filter((item: any) => item.id !== payload.id),
            payload,
          ],
        };
      })
      .addCase(editUserGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(deleteUserGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(deleteUserGroup.fulfilled, (state, action) => {
        const groupId = action.payload;
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          data: (state.groups.data || []).filter(
            (item: any) => item.id !== groupId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteUserGroup.rejected, (state, action) => {
        state.users = {
          ...(state.users || {}),
          loading: false,
        };
      })
      .addCase(getPermissions.pending, (state, action) => {
        state.permissions = {
          ...(state.permissions || {}),
          loading: true,
        };
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        const groupId = action.payload;
        state.permissions = {
          ...(state.permissions || {}),
          loading: false,
          data: action.payload,
        };
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.permissions = {
          ...(state.permissions || {}),
          loading: false,
        };
      });
  },
});

export const {
  setLogout,
  setEditingUser,
  setEditingUserClose,
  setRemoveUser,
  setRemoveUserClose,
  setEditingUserGroup,
  setEditingUserGroupClose,
  setRemoveUserGroup,
  setRemoveUserGroupClose,
} = authSlice.actions;

export default authSlice.reducer;
