import axios from "../utils/axios";

class SeagmentsService {
  // Seagment Actions
  async getSeagments() {
    try {
      const response = await axios.get("/api/contact/seagments/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async filterSortSeagments(
    filterData,
    termData = null,
    isPagination = true,
    operator = "or",
    order = null,
    current = 1,
    pageSize = 50,
  ) {
    const checkedFilters = {};
    Object.keys(filterData).forEach((field) => {
      const checkedValues = Object.keys(filterData?.[field] || {}).filter(
        (value) => filterData?.[field]?.[value]
      );
      if (checkedValues.length) {
        checkedFilters[field] = checkedValues;
      }
    });
    const payload = {
      filters: checkedFilters,
    };
    if (termData) {
      payload["term"] = termData;
    }

    if (order) {
      payload["order"] = order;
    }

    payload["isPagination"] = isPagination;

    if (operator) {
      payload["operator"] = operator;
    }

    try {
      const response = await axios.post(
        `/api/contact/seagments/filter_sort_data/?page=${current}&page_size=${pageSize}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getSeagment(seagment_id) {
    try {
      const response = await axios.get(
        `/api/contact/seagments/${seagment_id}/`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createSeagment(payload) {
    try {
      const response = await axios.post("/api/contact/seagments/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editSeagment(payload) {
    const { seagment_id, data } = payload;
    try {
      const response = await axios.put(
        `/api/contact/seagments/${seagment_id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async removeSeagment(seagment_id) {
    try {
      await axios.delete(`/api/contact/seagments/${seagment_id}/`);
      return seagment_id;
    } catch (err) {
      return null;
    }
  }

  async retrieveSeagmentRecords(data) {
    try {
      const response = await axios.post("/api/contact/retrieve-seagment-records/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }
}

export default new SeagmentsService();
