import * as Yup from "yup";

export const getInitialValueByFieldType = (field_type: string) => {
  if (field_type === "int" || field_type === "decimal") {
    return 0;
  }

  if (field_type === "datetime") {
    const today = new Date();
    return today;
  }

  if (field_type === "boolean") {
    return false;
  }

  return "";
};

export const getValidationSchemaByFieldType = (
  field_name: string,
  field_type: string
) => {
  let fieldValidation: Yup.Schema = Yup.string().max(255);
  if (field_type === "int" || field_type === "decimal") {
    fieldValidation = Yup.number();
  }

  if (field_type === "datetime") {
    fieldValidation = Yup.date();
  }

  if (field_type === "boolean") {
    fieldValidation = Yup.boolean();
  }

  return fieldValidation.required(`${field_name} is required.`);
};

export const getDistinctFields = (
  contact: any,
  fields: string[]
): { [key: string]: string }[] => {
  const uniqueValues = new Map<string, string>();

  fields.forEach((field) => {
    if (contact[field] && !uniqueValues.has(contact[field]!)) {
      uniqueValues.set(contact[field]!, field);
    }
  });

  return Array.from(uniqueValues.entries()).map(([value, field]) => ({
    [field]: value,
  }));
};

export const customArrayToString = (arr: { [key: string]: string }[]): string => {
  return arr.map(obj => {
    const [field, value] = Object.entries(obj)[0];
    return `${field}: ${value}`;
  }).join(", ");
};