import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import { setSelectContactClose } from "../../data/redux/contacts/contacts";
import BaseModal from "../common/baseModal";
import SearchInput from "../../common/searchInput/searchInput";
import ContactService from "../../../services/contacts";
import { Empty } from "antd";
import pkg from "lodash";
import { closeModal } from "../../data/redux/modals/modals";
import { displayFieldDatetimeValue, isValidUrl } from "../../../utils/helper";

const { debounce } = pkg;

const PAGE_ROOT = "contact";

const SelectContactModal = () => {
  const dispatch = useDispatch<any>();

  const modalToggle = useSelector(
    (state: any) => state?.contact?.contacts?.select?.isOpen
  );
  const modalData = useSelector(
    (state: any) => state?.contact?.contacts?.select?.data
  );
  const { onSubmitFinish } = modalData || {};

  const [filterColumns, setFilterColumns] = useState<any[]>([]);

  const [fieldSearchResults, setFieldSearchResults] = useState<{
    [key: string]: [];
  }>({});

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const [filterSearchChecked, setFilterSearchChecked] = useState<{
    [key: string]: {
      [term: string]: true;
    };
  }>({});
  const [searchTerm, setSearchTerm] = useState<any>("");

  const [searchResults, setSearchResults] = useState<any[]>([]);

  const getContactEmails = (contact: any) => {
    const emails = [contact.email1, contact.email2, contact.email3].filter(
      (email: any) => email && email.length > 0
    );
    return emails.join(", ");
  };

  const getContactPhones = (contact: any) => {
    const phones = [contact.phone1, contact.phone2, contact.phone3].filter(
      (phone: any) => phone && phone.length > 0
    );
    return phones.join(", ");
  };

  const onSearch = (value: string) => {
    if (!value || !value.length) {
      setSearchTerm(null);
      return;
    }

    if (value?.length >= 3) {
      setSearchTerm(value);
    }
  };

  const debouncedFilter = useMemo(
    () =>
      debounce(
        async (params: {
          filters?: any;
          term?: string;
          order?: any;
          currentPage?: number;
          pageSize?: number;
        }) => {
          const response = await ContactService.filterSortContacts(
            params.filters || filterSearchChecked,
            params.term || searchTerm,
            false
          );

          if (!response) {
            throw new Error("Search Field Values Failed!");
          }
          console.log("response", response);
          setSearchResults(response);
        },
        1000
      ),
    [dispatch, setSearchResults]
  );

  const onFilterRecords = () => {
    debouncedFilter({
      filters: filterSearchChecked,
      term: "",
    });
  };

  const onFieldSearch = async (field: string, value: string) => {
    if (!value || !value.length) {
      setFieldSearchResults({});
      return;
    }

    if (value?.length >= 3) {
      const response = await ContactService.searchFieldValues(field, value);

      if (!response) {
        throw new Error("Search Field Values Failed!");
      }

      setFieldSearchResults((prev) => ({
        ...(prev || {}),
        [field]: response,
      }));
    }
  };

  const onFilterItemHandler = (field: string, e: any) => {
    const isFilterItemChecked = e.target.checked;
    setFilterSearchChecked((prev: any) => {
      let fieldSelectedItems: {} = {
        ...(prev?.[field] || {}),
        [e.target.value]: isFilterItemChecked,
      };

      return {
        ...prev,
        [field]: fieldSelectedItems,
      };
    });
  };

  useEffect(() => {
    if (searchTerm || Object.keys(filterSearchChecked).length) {
      debouncedFilter({
        filters: filterSearchChecked,
        term: searchTerm,
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    if (
      fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.["data"]?.length
    ) {
      const filters = fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.[
        "data"
      ]?.filter(
        (fieldConfiguration: any) =>
          fieldConfiguration.is_filter &&
          fieldConfiguration.filter_type === "multi-select"
      );
      setFilterColumns(filters);
    }
  }, [JSON.stringify(fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"])]);

  useEffect(() => {
    return () => {
      debouncedFilter.cancel();
      setSearchResults([]);
    };
  }, []);

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setSelectContactClose());
        dispatch(closeModal("select_contact"));
        setSearchResults([]);
        setSearchTerm(null);
        setFilterSearchChecked({});
        setFieldSearchResults({});
      }}
      title="Select Contact"
      className="sidebar-popup"
      modalId={"select_contact"}
    >
      <div className="pro-create">
        <div className="row gap-5">
          <div className="col-xl-12">
            <div className="d-flex justify-content-between gap-3">
              <div className="form-wrap icon-form flex-grow-1">
                <span className="form-icon">
                  <i className="ti ti-search" />
                </span>
                <SearchInput
                  className="form-control"
                  placeholder={`Search Contact`}
                  onDebounce={(value: string) => {
                    onSearch(value);
                  }}
                />
              </div>
              <div className="form-sorts dropdown text-end">
                <Link
                  to="#"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                >
                  <i className="ti ti-filter-share" />
                  Filter
                </Link>
                <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-xl-end">
                  <div className="filter-set-view">
                    <div className="filter-set-head">
                      <h4>
                        <i className="ti ti-filter-share" />
                        Filter
                      </h4>
                    </div>

                    <div className="accordion" id="accordionExample">
                      {filterColumns.map((filterCol) => (
                        <div
                          className="filter-set-content"
                          key={filterCol.original_name}
                        >
                          <div className="filter-set-content-head">
                            <Link
                              to="#"
                              className="collapsed"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse-${filterCol.original_name?.toLowerCase()}`}
                              aria-expanded="false"
                              aria-controls={`collapse-${filterCol.original_name?.toLowerCase()}`}
                            >
                              {filterCol.display_name}
                            </Link>
                          </div>
                          <div
                            className="filter-set-contents accordion-collapse collapse"
                            id={`collapse-${filterCol.original_name?.toLowerCase()}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="filter-content-list">
                              <div className="form-wrap icon-form">
                                <span className="form-icon">
                                  <i className="ti ti-search" />
                                </span>
                                <SearchInput
                                  className="form-control"
                                  placeholder={`Search ${filterCol.display_name}`}
                                  onDebounce={(value: string) => {
                                    onFieldSearch(
                                      filterCol.original_name,
                                      value
                                    );
                                  }}
                                />
                              </div>
                              <ul>
                                {(
                                  fieldSearchResults?.[
                                    filterCol.original_name
                                  ] || []
                                ).map((searchItem) => (
                                  <li key={searchItem}>
                                    <div className="filter-checks">
                                      <label className="checkboxs">
                                        <input
                                          type="checkbox"
                                          value={searchItem}
                                          id={`checkebox-${filterCol.original_name}-${searchItem}`}
                                          checked={
                                            filterSearchChecked?.[
                                              filterCol.original_name
                                            ]?.[searchItem]
                                          }
                                          onChange={(e) =>
                                            onFilterItemHandler(
                                              filterCol.original_name,
                                              e
                                            )
                                          }
                                        />
                                        <span className="checkmarks" />
                                      </label>
                                    </div>
                                    <div className="collapse-inside-text">
                                      <label
                                        htmlFor={`checkebox-${filterCol.original_name}-${searchItem}`}
                                      >
                                        <h5>{searchItem}</h5>
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="filter-reset-btns">
                      <div className="row">
                        <div className="col-6">
                          <Link
                            to="#"
                            className="btn btn-light"
                            onClick={() => {
                              setFilterSearchChecked({});
                            }}
                          >
                            Reset
                          </Link>
                        </div>
                        <div className="col-6">
                          <Link
                            to={"#"}
                            className="btn btn-primary"
                            onClick={() => {
                              onFilterRecords();
                            }}
                          >
                            Filter
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            {!searchResults?.length ? (
              <Empty description="No Data" />
            ) : (
              <div className="accordion searchResultsAccordion">
                {searchResults.map((result) => (
                  <div
                    className="accordion-item mb-2 border rounded"
                    key={result.contact_id}
                  >
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-contact-${result.contact_id}`}
                        aria-expanded="false"
                      >
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <span className="me-3">ID: {result.contact_id}</span>
                          <span className="me-3">{result.full_name}</span>
                          <span className="me-3">
                            {getContactEmails(result)}
                          </span>
                          <span>{getContactPhones(result)}</span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`collapse-contact-${result.contact_id}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#searchResultsAccordion"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          {fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.[
                            "main"
                          ]?.["data"]
                            ?.filter((field: any) => field.is_show_popup)
                            .map((field: any) => (
                              <div
                                className="col-md-6 mb-2"
                                key={field.original_name}
                              >
                                <div className="d-flex">
                                  <strong className="me-2">
                                    {field.display_name}:
                                  </strong>
                                  <span>
                                    {result?.[field.original_name] ? (
                                      isValidUrl(
                                        result[field.original_name]
                                      ) ? (
                                        <div
                                          className="text-truncate"
                                          style={{
                                            maxWidth: "250px",
                                          }}
                                        >
                                          <a
                                            href={result[field.original_name]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {result[field.original_name]}
                                          </a>
                                        </div>
                                      ) : typeof result?.[
                                          field.original_name
                                        ] === "object" ? (
                                        Object.entries(
                                          result?.[field.original_name] || {}
                                        ).map(([key, value]: [string, any]) =>
                                          value ? (
                                            <div>
                                              <span>{key}</span>:
                                              <span>{value}</span>
                                            </div>
                                          ) : null
                                        )
                                      ) : field.display_field_type ===
                                          "datetime" ||
                                        field.display_field_type === "date" ? (
                                        displayFieldDatetimeValue(
                                          result?.[field.original_name],
                                          field.display_field_type
                                        )
                                      ) : (
                                        result?.[field.original_name]
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="text-end mt-3">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              onSubmitFinish(result.contact_id);
                              dispatch(setSelectContactClose());
                              dispatch(closeModal("select_contact"));
                              setSearchResults([]);
                              setSearchTerm(null);
                              setFilterSearchChecked({});
                              setFieldSearchResults({});
                            }}
                          >
                            Select Contact
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default SelectContactModal;
