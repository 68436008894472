import React from "react";
import LeadDetailModal from "../leads/detail_lead_modal";
import AddContactModal from "../contacts/manage/add_contact_modal";
import RemoveLeadModal from "../leads/remove_lead_modal";
import RemoveContactModal from "../contacts/manage/remove_contact_modal";
import RemoveCompanyModal from "../companies/remove_company_modal";
import ContactDetailModal from "../contacts/detail_contact_modal";
import CompanyDetailModal from "../companies/detail_company_modal";
import AddLeadModal from "../leads/add_lead_modal";
import SelectContactModal from "../contacts/select_contact_modal";
import SelectCompanyModal from "../companies/select_company_modal";
import AddCompanyModal from "../companies/add_company_modal";
import SelectLeadModal from "../leads/select_lead_modal";
import { useSelector } from "react-redux";
import SeamentFiltersModal from "../seagments/seagments_filter_modal";
import AddSeagmentModal from "../seagments/add_seagment_modal";
import RemoveSeagmentModal from "../seagments/remove_seagment_modal";
import SeagmentPreviewModal from "../seagments/seagment_preview_modal";

const ModalManager: React.FC = () => {
  const leadModalToggle = useSelector(
    (state: any) => state?.lead?.leads?.edit?.loading
  );
  const contactDetailModalToggle = useSelector(
    (state: any) => state?.contact?.contacts?.view?.loading
  );

  const seagmentFiltersModalToggle = useSelector(
    (state: any) => !!state?.modal?.activeModals?.["seagments_filters"]?.order
  );

  return (
    <>
      {/* Detail Modals */}
      <CompanyDetailModal />
      <LeadDetailModal />
      {contactDetailModalToggle && <ContactDetailModal />}

      {/* Select Modals */}
      <SelectContactModal />
      <SelectCompanyModal />
      <SelectLeadModal />

      {/* Add Modals */}
      {leadModalToggle && <AddLeadModal />}
      <AddContactModal />
      <AddCompanyModal />

      {/* Remove Modals */}
      <RemoveLeadModal />
      <RemoveContactModal />
      <RemoveCompanyModal />

      {/* Seagments Modals */}
      <AddSeagmentModal />
      {seagmentFiltersModalToggle && <SeamentFiltersModal />}
      <RemoveSeagmentModal />
      <SeagmentPreviewModal />
    </>
  );
};

export default ModalManager;
