import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseModal from "../common/baseModal";
import { closeModal } from "../../data/redux/modals/modals";
import "./index.scss";

interface FilterCondition {
  field: string;
  operator: string;
  value: string;
}

interface FilterGroup {
  operator: "AND" | "OR";
  filters: (FilterCondition | FilterGroup)[];
}

const SeamentFiltersModal = () => {
  const dispatch = useDispatch<any>();

  const activeModalData = useSelector(
    (state: any) => state?.modal?.activeModals
  );
  const filterModalData = activeModalData?.["seagments_filters"];

  const modalToggle = !!filterModalData?.order;
  const onApplyFilters = filterModalData?.onSubmitFinish;
  const modalData = filterModalData?.modalData;
  const currentService = modalData?.service;

  const [rootFilter, setRootFilter] = useState<FilterGroup>({
    ...(modalData?.["filters"] || {
      operator: "AND",
      filters: [],
    }),
  });

  const tableFieldsData =
    useSelector((state: any) => state.fieldsAdmin?.model) || {};
  // Get associated tables based on the service
  const getAssociatedTables = (service: string) => {
    // Define associated tables for each service
    const serviceAssociations: Record<string, string[]> = {
      Lead: ["Contact", "Company"],
      Contact: ["Lead", "Company"],
      Company: ["Lead", "Contact"],
      // Add more service associations as needed
    };

    return [service, ...(serviceAssociations?.[service] || [])];
  };

  // Get all fields from primary and associated tables
  const associatedTables = getAssociatedTables(currentService);

  // Combine fields from all associated tables
  const allFields = associatedTables.reduce(
    (fields: any[], tableName: string) => {
      const formattedTableName =
        tableName === "Contact" ? "ContactNew" : tableName;
      const tableFields = tableFieldsData?.[formattedTableName]?.["data"] || [];

      const formattedFields = tableFields.map((field: any) => ({
        value:
          tableName === currentService
            ? field.model_field_name
            : `${tableName}.${field.model_field_name}`,
        label:
          tableName === currentService
            ? field.model_field_name
            : `${tableName}.${field.model_field_name}`,
        display_type: field.model_field_type
      }));

      return [...fields, ...formattedFields];
    },
    []
  );

  const operatorOptions = [
    { value: "Contains", label: "Contains" },
    { value: "Does not contain", label: "Does not contain" },
    { value: "Exist", label: "Exist" },
    { value: "Does not exist", label: "Does not exist" },
    { value: "Equal", label: "Equal" },
  ];

  // Add this useEffect to properly initialize the state from modalData
  useEffect(() => {
    if (modalData?.["filters"]) {
      // Create a deep copy to avoid reference issues
      const deepCopy = JSON.parse(JSON.stringify(modalData["filters"]));
      setRootFilter(deepCopy);
    } else {
      setRootFilter({
        operator: "AND",
        filters: [],
      });
    }
  }, [modalData]);

  const renderFilterGroup = (group: FilterGroup, path: number[] = []) => {
    return (
      <div className="filter-group mb-3">
        <div className="d-flex">
          {/* Group operator on the left */}
          <div className="me-3  d-flex flex-column align-items-center">
            <button
              className="operator-badge btn btn-sm mb-2"
              style={{
                backgroundColor: "#e6f0ff",
                color: "#0d6efd",
                padding: "8px",
                borderRadius: "4px",
                fontWeight: "500",
                minWidth: "50px",
                textAlign: "center",
              }}
              onClick={() => {
                const newOperator = group.operator === "AND" ? "OR" : "AND";
                updateGroupOperator(path, newOperator);
              }}
            >
              {group.operator}
            </button>

            {/* Group remove button */}
            {path.length > 0 && (
              <button
                className="btn btn-light btn-sm"
                onClick={(e) => removeFilterGroup(path, e)}
                style={{ marginLeft: "10px", alignSelf: "flex-start" }}
              >
                <i className="ti ti-trash"></i>
              </button>
            )}
          </div>

          {/* Filter content */}
          <div className="flex-grow-1">
            {(group?.filters || [])?.map((filter, index) => {
              const currentPath = [...path, index];

              if ("operator" in filter && "filters" in filter) {
                // It's a nested filter group
                return (
                  <div
                    key={index}
                    className="nested-group mb-3 position-relative"
                  >
                    {renderFilterGroup(filter as FilterGroup, currentPath)}
                  </div>
                );
              } else {
                // It's a filter condition
                return renderFilterCondition(
                  filter as FilterCondition,
                  currentPath
                );
              }
            })}

            {/* Add condition/group buttons */}
            <div className="mt-3">
              <button
                className="btn btn-sm me-2"
                style={{
                  backgroundColor: "#e6f0ff",
                  color: "#0d6efd",
                }}
                onClick={(e) => addFilterCondition(path, e)}
              >
                <i className="ti ti-plus"></i> Filter
              </button>
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#e6f0ff",
                  color: "#0d6efd",
                }}
                onClick={(e) => addFilterGroup(path, e)}
              >
                <i className="ti ti-plus"></i> Group
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFilterCondition = (
    condition: FilterCondition,
    path: number[]
  ) => {
    // Find the selected field's display type
    const selectedField = allFields.find(field => field.value === condition.field);
    const displayType = selectedField?.display_type || 'char';
  
    // Parse datetime range values if available
    let fromValue = "", toValue = "";
    if (displayType === 'datetime' && condition.value) {
      try {
        const dateRange = JSON.parse(condition.value);
        fromValue = dateRange.from || "";
        toValue = dateRange.to || "";
      } catch (e) {
        // Handle parsing error if needed
      }
    }
  
    return (
      <div
        key={path.join("-")}
        className="filter-segment-row d-flex align-items-center mb-3"
        style={{ gap: "10px" }}
      >
        {/* Field name dropdown */}
        <div style={{ width: "150px" }}>
          <select
            className="form-select"
            value={condition.field}
            onChange={(e) => updateFilterField(path, e.target.value)}
          >
            <option value="">Select Field</option>
            {allFields.map((field) => (
              <option key={field.value} value={field.value}>
                {field.label}
              </option>
            ))}
          </select>
        </div>
  
        {/* Operator dropdown - conditional rendering based on display_type */}
        {displayType !== 'datetime' && (
          <div className="dropdown-container" style={{ width: "120px" }}>
            <select
              className="form-select"
              value={condition.operator}
              onChange={(e) => updateFilterOperator(path, e.target.value)}
            >
              {displayType === 'boolean' ? (
                <option value="is">is</option>
              ) : (
                operatorOptions.map((op) => (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                ))
              )}
            </select>
          </div>
        )}
  
        {/* Value input - conditional rendering based on display_type */}
        <div className="flex-grow-1">
          {displayType === 'boolean' ? (
            <select
              className="form-select"
              value={condition.value}
              onChange={(e) => updateFilterValue(path, e.target.value)}
            >
              <option value="">Select</option>
              <option value={"true"}>Yes</option>
              <option value="false">No</option>
            </select>
          ) : displayType === 'datetime' ? (
            <div>
              <div className="mb-3">
                <label className="form-label">From</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  value={fromValue}
                  onChange={(e) => updateDateRangeValue(path, e.target.value, toValue)}
                />
              </div>
              <div>
                <label className="form-label">To</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  value={toValue}
                  onChange={(e) => updateDateRangeValue(path, fromValue, e.target.value)}
                />
              </div>
            </div>
          ) : (
            <input
              type="text"
              className="form-control"
              value={condition.value || ""}
              onChange={(e) => updateFilterValue(path, e.target.value)}
              placeholder="Value"
            />
          )}
        </div>
  
        {/* Remove button */}
        <button
          className="btn btn-light btn-sm"
          onClick={(e) => removeFilterCondition(path, e)}
        >
          <i className="ti ti-trash"></i>
        </button>
      </div>
    );
  };

  // Helper function to update a nested value
  const updateNestedValue = (
    obj: FilterGroup,
    path: number[],
    updateFn: Function
  ): FilterGroup => {

    // If path is empty, apply the update directly to the root object
    if (path.length === 0) {
      return updateFn(obj);
    }
    
    // For nested updates
    const [index, ...restPath] = path;
    const newFilters = [...(obj?.filters || [])];

    if (restPath.length === 0) {
      // We're at the target level
      newFilters[index] = updateFn(newFilters[index]);
    } else {
      // We need to go deeper
      newFilters[index] = updateNestedValue(
        newFilters[index] as FilterGroup,
        restPath,
        updateFn
      );
    }

    return {
      ...obj,
      filters: newFilters,
    };
  };

  // Update functions for filter groups and conditions
  const updateGroupOperator = (path: number[], operator: "AND" | "OR") => {
    setRootFilter((prev) => {
      if (path.length === 0) {
        return { ...prev, operator };
      }

      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (group: FilterGroup) => ({
          ...group,
          operator,
        })
      );
    });
  };

  const addFilterCondition = (path: number[], e?: React.MouseEvent) => {
    if (e) e.stopPropagation();

    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      const result = updateNestedValue(
        currentRootFilter,
        path,
        (group: FilterGroup) => ({
          ...group,
          filters: [
            ...(group?.filters || []),
            { field: "", operator: "Contains", value: "" },
          ],
        })
      );
      return result;
    });
  };

  const addFilterGroup = (path: number[], e?: React.MouseEvent) => {
    if (e) e.stopPropagation();

    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (group: FilterGroup) => ({
          ...group,
          filters: [...(group?.filters || []), { operator: "AND", filters: [] }],
        })
      );
    });
  };

  const removeFilterGroup = (path: number[], e?: React.MouseEvent) => {
    if (e) e.stopPropagation();

    setRootFilter((prev) => {
      const parentPath = path.slice(0, -1);
      const index = path[path.length - 1];

      const currentRootFilter = { ...prev };

      return updateNestedValue(
        currentRootFilter,
        parentPath,
        (group: FilterGroup) => ({
          ...group,
          filters: (group?.filters || [])?.filter((_, i) => i !== index),
        })
      );
    });
  };

  const updateFilterField = (path: number[], field: string) => {
    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (condition: FilterCondition) => ({
          ...condition,
          field,
        })
      );
    });
  };

  const updateFilterOperator = (path: number[], operator: string) => {
    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (condition: FilterCondition) => ({
          ...condition,
          operator,
        })
      );
    });
  };

  const updateFilterValue = (path: number[], value: string) => {
    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (condition: FilterCondition) => ({
          ...condition,
          value,
        })
      );
    });
  };

  const removeFilterCondition = (path: number[], e?: React.MouseEvent) => {
    if (e) e.stopPropagation();

    setRootFilter((prev) => {
      const parentPath = path.slice(0, -1);
      const index = path[path.length - 1];

      const currentRootFilter = { ...prev };

      return updateNestedValue(
        currentRootFilter,
        parentPath,
        (group: FilterGroup) => ({
          ...group,
          filters: (group?.filters || []).filter((_, i) => i !== index),
        })
      );
    });
  };

  const updateDateRangeValue = (path: number[], fromValue: string, toValue: string) => {
    setRootFilter((prev) => {
      const currentRootFilter = { ...prev };
      return updateNestedValue(
        currentRootFilter,
        path,
        (condition: FilterCondition) => ({
          ...condition,
          value: JSON.stringify({ from: fromValue, to: toValue }),
        })
      );
    });
  };

  const handleApply = () => {
    if (onApplyFilters) {
      // Create a deep copy before passing to the callback
      const deepCopy = JSON.parse(JSON.stringify(rootFilter));
      onApplyFilters(deepCopy);
    }
    dispatch(closeModal("seagments_filters"));
  };

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => dispatch(closeModal("seagments_filters"))}
      title="Seagment Filters"
      className="sidebar-popup filter-contacts-modal"
      modalId="seagments_filters"
    >
      <div className="filter-segments-container">
        {renderFilterGroup(rootFilter)}
      </div>

      <div className="d-flex justify-content-end mt-4">
        <button className="btn btn-success" onClick={handleApply}>
          Apply
        </button>
      </div>
    </BaseModal>
  );
};

export default SeamentFiltersModal;
