import { createAsyncThunk } from "@reduxjs/toolkit";
import LeadService from "../../../../services/leads";

// Lead Actions
export const getLeads = createAsyncThunk(
  "lead/retrieve-leads",
  async (payload: any) => {
    const response = await LeadService.getLeads(payload);
    if (!response) {
      throw new Error("Retrieve Leads Failed");
    }

    return response;
  }
);

export const filterSortLead = createAsyncThunk(
  "lead/filter-sort-leads",
  async (payload: any) => {
    const { filters, term, operator, order, currentPage, pageSize } = payload;
    const response = await LeadService.filterSortLeads(
      filters,
      term,
      true,
      operator,
      order,
      currentPage,
      pageSize
    );
    if (!response) {
      throw new Error("Filter Leads Failed");
    }

    return response;
  }
);

export const getLead = createAsyncThunk(
  "lead/retrieve-lead",
  async (payload: any) => {
    const response = await LeadService.getLead(payload);
    if (!response) {
      throw new Error("Retrieve Lead Failed");
    }

    return response;
  }
);

export const createLead = createAsyncThunk(
  "lead/create-lead",
  async (payload: any) => {
    const response = await LeadService.createLead(payload);
    if (!response) {
      throw new Error("Create Lead Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editLead = createAsyncThunk(
  "lead/edit-lead",
  async (payload: any) => {
    const response = await LeadService.editLead(payload);
    if (!response) {
      throw new Error("Edit Lead Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteLead = createAsyncThunk(
  "lead/delete-lead",
  async (payload: any) => {
    const response = await LeadService.removeLead(payload);
    if (!response) {
      throw new Error("Delete Lead Failed!");
    }

    return response;
  }
);

export const createLeadComment = createAsyncThunk(
  "lead/create-lead-comment",
  async (payload: any) => {
    const response = await LeadService.createComment(payload);
    if (!response) {
      throw new Error("Create Lead Comment Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editLeadComment = createAsyncThunk(
  "lead/edit-lead-comment",
  async (payload: any) => {
    const response = await LeadService.editComment(payload);
    if (!response) {
      throw new Error("Edit Lead Comment Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteLeadComment = createAsyncThunk(
  "lead/delete-lead-comment",
  async (payload: any) => {
    const response = await LeadService.removeComment(payload);
    if (!response) {
      throw new Error("Delete Lead Comment Failed!");
    }

    return response;
  }
);
