import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import {
  setCompanyViewClose,
  setEditingCompany,
} from "../../data/redux/companies/companies";
import { getCompany } from "../../data/redux/companies/thunk";
import { Empty } from "antd";
import { Flex } from "antd";
import BaseModal from "../common/baseModal";
import {
  setEditingLead,
  setLeadView,
  setSelectLead,
} from "../../data/redux/leads/leads";
import {
  setContactView,
  setEditingContact,
  setSelectContact,
} from "../../data/redux/contacts/contacts";
import { ToastContainer, toast } from "react-toastify";
import CompanyService from "../../../services/company";
import { closeModal, setActiveModal } from "../../data/redux/modals/modals";
import { displayFieldDatetimeValue, isValidUrl } from "../../../utils/helper";

const InfoRow = ({
  display_name,
  value,
  type,
}: {
  display_name: string;
  value: any;
  type: string;
}) => {
  const formattedValue = useMemo(() => {
    if (value && type == "datetime") {
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(new Date(value));
    }

    if (value && type == "date") {
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(new Date(value));
    }
    return value ?? "";
  }, [value, type]);

  return (
    <li className="detail-item">
      <span className="other-title h6">{display_name}</span>
      <span
        style={{
          wordBreak: "keep-all",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "250px",
        }}
      >
        {type === "boolean" ? (
          <Flex gap="4px 0" className="align-items-center">
            <div className="form-switch form-check-md">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={value || false}
                disabled
              />
            </div>
          </Flex>
        ) : (
          <>{formattedValue}</>
        )}
      </span>
    </li>
  );
};

const CompanyDetailModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.company.companies?.view?.loading
  );
  const modalData = useSelector(
    (state: any) => state?.company.companies?.view?.data
  );
  const { visitingCompanyData } = modalData || {};

  const [activeTab, setActiveTab] = useState("leads");

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const companyInternalDataFields = (
    fieldsAdminData?.["contact"]?.["Company"]?.["main"]?.["data"] || []
  ).filter((field: any) => field?.is_show_popup);

  useEffect(() => {
    if (visitingCompanyData?.company_id) {
      dispatch(getCompany(visitingCompanyData.company_id));
    }
  }, [visitingCompanyData?.company_id]);

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setCompanyViewClose());
        dispatch(closeModal("detail_company"));
      }}
      title="Company Detail"
      className="sidebar-popup"
      modalId={"detail_company"}
    >
      <div className="pro-create">
        <div className="row">
          <div className="col-xl-12">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link
                    to="#"
                    className={activeTab === "detail" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("detail");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Detail
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={activeTab === "leads" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("leads");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Leads
                  </Link>
                </li>

                <li>
                  <Link
                    to="#"
                    className={activeTab === "contacts" ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveTab("contacts");
                    }}
                  >
                    <i className="ti ti-notes" />
                    Contacts
                  </Link>
                </li>
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* Basic */}
                <div
                  className={`tab-pane ${
                    activeTab === "detail" ? "active show" : ""
                  }`}
                  id="detail"
                >
                  <div className="view-header">
                    <h4>Detail</h4>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch(
                          setEditingCompany({
                            editingData: visitingCompanyData,
                            onSubmitFinish: () => {
                              dispatch(
                                getCompany(visitingCompanyData?.company_id)
                              );
                            },
                          })
                        );
                        dispatch(setActiveModal({ modalId: "add_company" }));
                      }}
                    >
                      <i className="ti ti-edit mr-2"></i> Edit
                    </button>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      {(companyInternalDataFields || []).map((field: any) => (
                        <InfoRow
                          display_name={field.display_name}
                          key={field.original_name}
                          value={visitingCompanyData?.[field.original_name]}
                          type={field.display_field_type}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "leads" ? "active show" : ""
                  }`}
                  id="leads"
                >
                  <div className="view-header">
                    <h4>Leads</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Lead
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectLead({
                                  onSubmitFinish: async (lead_id: string) => {
                                    try {
                                      const response =
                                        await CompanyService.addLeadToCompany({
                                          company_id:
                                            visitingCompanyData?.company_id,
                                          lead_id: lead_id,
                                        });
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getCompany(
                                          visitingCompanyData?.company_id
                                        )
                                      );
                                      toast.success("Lead Added Successfully", {
                                        position: "top-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message || "Failed to Add Lead",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({
                                  modalId: "select_lead",
                                })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Lead
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingLead({
                                  editingData: {
                                    lead_id: "add",
                                    company_id: visitingCompanyData?.company_id,
                                  },
                                  pageRoot: "lead",
                                  modelCategory: "main",
                                  onSubmitFinish: (company_id: string) => {
                                    dispatch(getCompany(company_id));
                                  },
                                })
                              );
                              dispatch(setActiveModal({ modalId: "add_lead" }));
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Lead
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingCompanyData?.company_leads?.length ? (
                          (visitingCompanyData?.company_leads || []).map(
                            (lead: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={lead.lead_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-lead-${lead.lead_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {lead.lead_id}
                                      </span>
                                      <span className="me-3">
                                        {lead.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setLeadView({
                                                visitingLeadData: lead,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_lead",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            try {
                                              const response =
                                                await CompanyService.addLeadToCompany(
                                                  {
                                                    company_id:
                                                      visitingCompanyData?.company_id,
                                                    lead_id: lead?.lead_id,
                                                    is_add: false,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getCompany(
                                                  visitingCompanyData?.company_id
                                                )
                                              );
                                              toast.success(
                                                "Lead Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Lead",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-lead-${lead.lead_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.["lead"]?.["Lead"]?.[
                                        "main"
                                      ]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              <span>
                                                {lead?.[field.original_name] ? (
                                                  isValidUrl(
                                                    lead[field.original_name]
                                                  ) ? (
                                                    <div
                                                      className="text-truncate"
                                                      style={{
                                                        maxWidth: "250px",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          lead[
                                                            field.original_name
                                                          ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {
                                                          lead[
                                                            field.original_name
                                                          ]
                                                        }
                                                      </a>
                                                    </div>
                                                  ) : typeof lead?.[
                                                      field.original_name
                                                    ] === "object" ? (
                                                    Object.entries(
                                                      lead?.[
                                                        field.original_name
                                                      ] || {}
                                                    ).map(
                                                      ([key, value]: [
                                                        string,
                                                        any
                                                      ]) =>
                                                        value ? (
                                                          <div>
                                                            <span>{key}</span>:
                                                            <span>{value}</span>
                                                          </div>
                                                        ) : null
                                                    )
                                                  ) : field.display_field_type ===
                                                      "datetime" ||
                                                    field.display_field_type ===
                                                      "date" ? (
                                                    displayFieldDatetimeValue(
                                                      lead?.[
                                                        field.original_name
                                                      ],
                                                      field.display_field_type
                                                    )
                                                  ) : (
                                                    lead?.[field.original_name]
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "contacts" ? "active show" : ""
                  }`}
                  id="contacts"
                >
                  <div className="view-header">
                    <h4>Contacts</h4>
                    <div className="text-end">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Link Contact
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            padding: "5px",
                            transform:
                              "translate3d(-99.3333px, 35.3333px, 0px)",
                          }}
                          data-popper-placement="bottom-start"
                        >
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setSelectContact({
                                  onSubmitFinish: async (
                                    contact_id: string
                                  ) => {
                                    try {
                                      const response =
                                        await CompanyService.addContactToCompany(
                                          {
                                            company_id:
                                              visitingCompanyData?.company_id,
                                            contact_id: contact_id,
                                          }
                                        );
                                      if (response?.error) {
                                        throw new Error(response?.error);
                                      }

                                      await dispatch(
                                        getCompany(
                                          visitingCompanyData?.company_id
                                        )
                                      );
                                      toast.success(
                                        "Contact Added Successfully",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    } catch (error: any) {
                                      toast.error(
                                        error?.message ||
                                          "Failed to Add Contact",
                                        {
                                          position: "top-left",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        }
                                      );
                                    }
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "select_contact" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Select Existing Contact
                          </Link>
                          <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => {
                              dispatch(
                                setEditingContact({
                                  editingData: {
                                    contact_id: "add",
                                    company_id: visitingCompanyData?.company_id,
                                  },
                                  onSubmitFinish: (company_id: string) => {
                                    dispatch(getCompany(company_id));
                                  },
                                })
                              );
                              dispatch(
                                setActiveModal({ modalId: "add_contact" })
                              );
                            }}
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Add New Contact
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="notes-activity">
                    <ul className="other-info">
                      <div className="accordion searchResultsAccordion">
                        {visitingCompanyData?.company_contacts?.length ? (
                          (visitingCompanyData?.company_contacts || []).map(
                            (contact: any) => (
                              <div
                                className="accordion-item mb-2 border rounded"
                                key={contact.contact_id}
                              >
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button brief-accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-contact-${contact.contact_id}`}
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex w-100 justify-content-between align-items-center">
                                      <span className="me-3">
                                        ID: {contact.contact_id}
                                      </span>
                                      <span className="me-3">
                                        {contact.full_name}
                                      </span>

                                      <div className="d-flex justify-content-end px-4 py-1 gap-3 fs-5">
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            dispatch(
                                              setContactView({
                                                visitingContactData: contact,
                                              })
                                            );
                                            dispatch(
                                              setActiveModal({
                                                modalId: "detail_contact",
                                              })
                                            );
                                          }}
                                        >
                                          <i className="ti ti-edit text-success"></i>{" "}
                                        </Link>

                                        <Link
                                          className=""
                                          to="#"
                                          onClick={async (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            try {
                                              const response =
                                                await CompanyService.addContactToCompany(
                                                  {
                                                    company_id:
                                                      visitingCompanyData?.company_id,
                                                    contact_id:
                                                      contact?.contact_id,
                                                    is_add: false,
                                                  }
                                                );
                                              if (response?.error) {
                                                throw new Error(
                                                  response?.error
                                                );
                                              }

                                              await dispatch(
                                                getCompany(
                                                  visitingCompanyData?.company_id
                                                )
                                              );
                                              toast.success(
                                                "Contact Unlinked Successfully",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            } catch (error: any) {
                                              toast.error(
                                                error?.message ||
                                                  "Failed to Unlink Contact",
                                                {
                                                  position: "top-left",
                                                  autoClose: 5000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <i className="ti ti-trash text-danger"></i>{" "}
                                        </Link>
                                      </div>
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-contact-${contact.contact_id}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#searchResultsAccordion"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      {fieldsAdminData?.["contact"]?.[
                                        "ContactNew"
                                      ]?.["main"]?.["data"]
                                        ?.filter(
                                          (field: any) => field.is_show_popup
                                        )
                                        .map((field: any) => (
                                          <div
                                            className="col-md-6 mb-2"
                                            key={field.original_name}
                                          >
                                            <div className="d-flex">
                                              <strong className="me-2">
                                                {field.display_name}:
                                              </strong>
                                              <span>
                                                {contact?.[
                                                  field.original_name
                                                ] ? (
                                                  isValidUrl(
                                                    contact[field.original_name]
                                                  ) ? (
                                                    <div
                                                      className="text-truncate"
                                                      style={{
                                                        maxWidth: "250px",
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          contact[
                                                            field.original_name
                                                          ]
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {
                                                          contact[
                                                            field.original_name
                                                          ]
                                                        }
                                                      </a>
                                                    </div>
                                                  ) : typeof contact?.[
                                                      field.original_name
                                                    ] === "object" ? (
                                                    Object.entries(
                                                      contact?.[
                                                        field.original_name
                                                      ] || {}
                                                    ).map(
                                                      ([key, value]: [
                                                        string,
                                                        any
                                                      ]) =>
                                                        value ? (
                                                          <div>
                                                            <span>{key}</span>:
                                                            <span>{value}</span>
                                                          </div>
                                                        ) : null
                                                    )
                                                  ) : field.display_field_type ===
                                                      "datetime" ||
                                                    field.display_field_type ===
                                                      "date" ? (
                                                    displayFieldDatetimeValue(
                                                      contact?.[
                                                        field.original_name
                                                      ],
                                                      field.display_field_type
                                                    )
                                                  ) : (
                                                    contact?.[
                                                      field.original_name
                                                    ]
                                                  )
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <Empty description="No Data" />
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                {/* Companies */}
              </div>
            </div>
            {/* /Tab Content */}
          </div>
        </div>
        <ToastContainer />
      </div>
    </BaseModal>
  );
};

export default CompanyDetailModal;
