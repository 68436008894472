import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Empty } from "antd";
import Table from "../../../core/common/dataTable/index";
import BaseModal from "../common/baseModal";
import { closeModal } from "../../data/redux/modals/modals";
import { SorterResult } from "antd/es/table/interface";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { displayFieldDatetimeValue } from "../../../utils/helper";
import SeagmentService from "../../../services/seagments";
import pkg from "lodash";
const { debounce, isEmpty } = pkg;

const MODEL_MAPPING: any = {
  contact: "ContactNew",
  lead: "Lead",
  company: "Company",
};

const PAGE_ROOT_MAPPING: any = {
  Contact: "contact",
  Lead: "lead",
  Company: "contact",
};

const SeagmentPreviewModal = () => {
  const dispatch = useDispatch<any>();
  const activeModalData = useSelector(
    (state: any) => state?.modal?.activeModals
  );
  const filterModalData = activeModalData?.["seagment_preview"];

  const modalToggle = !!filterModalData?.order;
  const modalData = filterModalData?.modalData;
  const { service, filters, name } = modalData || {};

  const pageRoot = PAGE_ROOT_MAPPING?.[service];

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const [columns, setColumns] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const [order, setOrder] = useState<{
    [field: string]: string | null;
  }>({});

  useEffect(() => {
    if (service) {
      try {
        const serviceTableName = MODEL_MAPPING[service?.toLowerCase()];

        if (serviceTableName) {
          const tableFields =
            fieldsAdminData?.[pageRoot]?.[serviceTableName]?.["main"]?.["data"];

          if (tableFields?.length) {
            const columnsData =
              tableFields
                ?.filter(
                  (fieldConfiguration: any) =>
                    fieldConfiguration.is_show_manage_table
                )
                ?.map((item: any, index: number) => ({
                  title: item.display_name,
                  dataIndex: item.original_name,
                  key: item.original_name,
                  width: item.width ?? 150,
                  align: "left",
                  ellipsis: true,
                  sorter: true,
                  sortDirections: ["ascend", "descend", null],
                  render: (text: string, record: any, index: number) => (
                    <div
                      className={`table-avatar d-flex ${
                        item.display_field_type === "email-with-icon" ||
                        item.display_field_type === "phone-with-icon"
                          ? "justify-content-center"
                          : "justify-content-start"
                      } align-items-center`}
                      key={index}
                    >
                      {record?.[item.original_name] ? (
                        item.display_field_type === "email-with-icon" ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="refresh-tooltip"
                                style={{ position: "fixed" }}
                              >
                                {record?.[item.original_name]}
                              </Tooltip>
                            }
                          >
                            <div className="mx-2">
                              <i className={"ti-email"}></i>
                            </div>
                          </OverlayTrigger>
                        ) : item.display_field_type === "phone-with-icon" ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="refresh-tooltip"
                                style={{ position: "fixed" }}
                              >
                                {record?.[item.original_name]}
                              </Tooltip>
                            }
                          >
                            <div className="mx-2">
                              <i className={"ti-headphone"}></i>
                            </div>
                          </OverlayTrigger>
                        ) : item.display_field_type === "datetime" ||
                          item.display_field_type === "date" ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="refresh-tooltip"
                                style={{ position: "fixed" }}
                              >
                                {displayFieldDatetimeValue(
                                  record?.[item.original_name],
                                  item.display_field_type
                                )}
                              </Tooltip>
                            }
                          >
                            <span
                              className={`profile-split cursor-pointer`}
                              style={{
                                cursor: "pointer",
                                wordBreak: "keep-all",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {displayFieldDatetimeValue(
                                record?.[item.original_name],
                                item.display_field_type
                              )}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="refresh-tooltip"
                                style={{ position: "fixed" }}
                              >
                                {record?.[item.original_name]
                                  ? typeof record?.[item.original_name] ===
                                    "object"
                                    ? JSON.stringify(
                                        record?.[item.original_name] || {}
                                      )
                                    : record?.[item.original_name]
                                  : ""}
                              </Tooltip>
                            }
                          >
                            <span
                              className={`profile-split cursor-pointer`}
                              style={{
                                cursor: "pointer",
                                wordBreak: "keep-all",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {record?.[item.original_name]
                                ? typeof record?.[item.original_name] ===
                                  "object"
                                  ? JSON.stringify(
                                      record?.[item.original_name] || {}
                                    )
                                  : record?.[item.original_name]
                                : ""}
                            </span>
                          </OverlayTrigger>
                        )
                      ) : null}
                    </div>
                  ),
                  ...(index === 0 || index === 1
                    ? {
                        fixed: "left",
                      }
                    : {}),
                })) || [];
            setColumns([...columnsData]);
          }
        }
      } catch (e: any) {}
    }
  }, [JSON.stringify(fieldsAdminData?.[pageRoot] || {}), service, pageRoot]);

  useEffect(() => {
    if (service) {
      setOrder((prev) => {
        if (isEmpty(prev)) {
          return {
            [service === "Contact"
              ? "contact_id"
              : service === "Lead"
              ? "lead_id"
              : service === "Company"
              ? "company_id"
              : ""]: "desc",
          };
        }
        return prev;
      });
    }
  }, [service]);

  const debouncedRecordsSort = useMemo(
    () =>
      debounce(
        async (params: {
          filters?: any;
          term?: string;
          operator?: string;
          order?: any;
          currentPage?: number;
          pageSize?: number;
          service?: string;
        }) => {
          const { service, pageSize, filters, order } = params;
          if (!service || !filters) return;

          setLoading(true);
          try {
            const response = await SeagmentService.retrieveSeagmentRecords({
              service,
              filters,
              limit: pageSize,
              order,
            });

            if (response.results) {
              setRecords(response.results || []);
            }
          } catch (error) {
            console.error("Error fetching filtered records:", error);
          } finally {
            setLoading(false);
          }
        },
        1000
      ),
    [dispatch]
  );

  useEffect(() => {
    if (service && filters && !isEmpty(order)) {
      debouncedRecordsSort({
        filters,
        order,
        currentPage: 1,
        pageSize,
        service,
      });
    }
  }, [service, JSON.stringify(filters), pageSize, JSON.stringify(order)]);

  const onSorterHandler = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    if (Array.isArray(sorter)) {
      const result: {
        [key: string]: string | null;
      } = {};
      sorter.map((sortItem: SorterResult<any>) => {
        const currentOrder =
          order?.[sortItem.columnKey as string] === undefined
            ? "ascend"
            : order?.[sortItem.columnKey as string] === null
            ? null
            : order?.[sortItem.columnKey as string];
        result[sortItem.columnKey as string] =
          currentOrder === "ascend"
            ? "descend"
            : currentOrder === "descend"
            ? null
            : "ascend";
      });
      setOrder((_) => {
        return {
          ...result,
        };
      });
    } else {
      if (sorter.columnKey) {
        setOrder((_) => {
          const currentOrder =
            order?.[sorter.columnKey as string] === undefined
              ? "ascend"
              : order?.[sorter.columnKey as string] === null
              ? null
              : order?.[sorter.columnKey as string];
          return {
            [sorter.columnKey as string]:
              currentOrder === "ascend"
                ? "descend"
                : currentOrder === "descend"
                ? null
                : "ascend",
          };
        });
      }
    }
  };

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => dispatch(closeModal("seagment_preview"))}
      title={`Preview: ${name || "Seagment"}`}
      className="preview-modal"
      modalId="seagment_preview"
      isCenter={true}
      isLarge={true}
    >
      <div className="preview-container">
        <div className="service-info mb-3">
          <h6>Seagment Service - {service}</h6>
        </div>

        <div className="records-table">
          {loading ? (
            <div className="text-center p-5">
              <Spin size="large" />
            </div>
          ) : columns && columns.length ? (
            <>
              <div className="table-responsive custom-table">
                <Table
                  dataSource={records}
                  columns={columns}
                  loading={loading}
                  onSorterHandler={onSorterHandler}
                  stickyHeader={true}
                  height={"calc(100vh - 200px)"}
                />
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="datatable-length" />
                </div>
                <div className="col-md-6">
                  <div className="datatable-paginate" />
                </div>
              </div>
            </>
          ) : (
            <Empty description="No matching records found" />
          )}
        </div>
      </div>
    </BaseModal>
  );
};

export default SeagmentPreviewModal;
