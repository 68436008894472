import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getInitialValueByFieldType,
  getValidationSchemaByFieldType,
} from "./utils/helper";
import { getErrorMessage } from "../../../utils/helper";
import BaseModal from "../common/baseModal";
import { closeModal, setActiveModal } from "../../data/redux/modals/modals";
import { setEditingSeagmentClose } from "../../data/redux/seagments/seagments";
import { createSeagment, editSeagment } from "../../data/redux/seagments/thunk";
import "./index.scss";

const AddSeagmentModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.seagment?.seagments?.edit?.loading
  );
  const modalData = useSelector(
    (state: any) => state?.seagment?.seagments?.edit?.data
  );

  const { editingData } = modalData || {};

  const fields = useMemo(() => {
    return [
      {
        original_name: "name",
        display_name: "Name",
        display_field_type: "text",
        is_required_popup: true,
      },
      {
        original_name: "service",
        display_name: "Service",
        display_field_type: "select",
        dropdown_options: [
          { value: "Lead", label: "Lead" },
          { value: "Contact", label: "Contact" },
          { value: "Company", label: "Company" },
        ],
        is_required_popup: true,
      },
      {
        original_name: "filters",
        display_name: "Filters",
        display_field_type: "filters",
        is_required_popup: true,
      },
      {
        original_name: "created_at",
        display_name: "Created At",
        display_field_type: "datetime",
      },
      {
        original_name: "updated_at",
        display_name: "Updated At",
        display_field_type: "datetime",
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (fields?.length) {
      const result = fields.reduce((initialData: any, currentField: any) => {
        const { original_name, display_field_type } = currentField;

        initialData[original_name] =
          editingData?.seagment_id === "add"
            ? getInitialValueByFieldType(display_field_type)
            : editingData?.[original_name];
        return initialData;
      }, {});
      return result;
    }
    return {};
  }, [JSON.stringify(fields), JSON.stringify(editingData)]);

  const validationSchema = useMemo(() => {
    if (fields?.length) {
      const result = fields.reduce((initialSchema: any, currentField: any) => {
        const { original_name, display_name, display_field_type } =
          currentField;
        initialSchema[original_name] = getValidationSchemaByFieldType(
          display_name,
          display_field_type
        );
        return initialSchema;
      }, {});

      return result;
    }
    return {};
  }, [JSON.stringify(fields)]);

  const getDateValue = (date: any) => {
    try {
      if (date) {
        return new Date(date);
      }
      return null;
    } catch (_) {
      return null;
    }
  };

  return (
    <BaseModal
      isOpen={modalToggle}
      onClose={() => {
        dispatch(setEditingSeagmentClose());
        dispatch(closeModal("add_seagment"));
      }}
      title={
        editingData?.seagment_id === "add"
          ? "Add New Seagment"
          : "Edit Seagment"
      }
      className="sidebar-popup"
      modalId={"add_seagment"}
    >
      <div className="toggle-body">
        <div className="pro-create">
          <Formik
            initialValues={{ ...initialValues, submit: null }}
            validationSchema={Yup.object().shape(validationSchema)}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm, setErrors }) => {
              try {
                let newSeagment: any = values;

                const payload = {
                  name: values?.name,
                  service: values?.service,
                  filters: values?.filters
                };

                let res: any;
                if (editingData?.seagment_id === "add") {
                  res = await dispatch(
                    createSeagment({
                      ...payload,
                    })
                  );
                } else {
                  res = await dispatch(
                    editSeagment({
                      seagment_id: editingData.seagment_id,
                      data: payload,
                    })
                  );
                }

                if (res && res?.error) {
                  let errorData;
                  try {
                    errorData = JSON.parse(res?.error?.message);
                    setErrors(errorData);
                  } catch (_) {
                    errorData = "";
                    setErrors({ submit: getErrorMessage(errorData) });
                  }
                } else {
                  // onSubmitFinish &&
                  //   onSubmitFinish(
                  //     editingData?.lead_id ?? editingData?.contact_id
                  //   );
                  resetForm();
                }
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {/* Seagment Info */}

                <div className="row gy-2">
                  {fields.map(
                    ({
                      display_name,
                      display_field_type,
                      original_name,
                      dropdown_options,
                      is_required_popup,
                    }) => (
                      <div className="col-md-12" key={original_name}>
                        <div className="form-wrap">
                          <label className="col-form-label">
                            {display_name}&nbsp;
                            {is_required_popup && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          {display_field_type === "select" ? (
                            <Select
                              defaultValue={[]}
                              name={original_name}
                              styles={{
                                menu: (base) => ({ ...base, zIndex: 10 }),
                              }}
                              options={dropdown_options || []}
                              onBlur={handleBlur}
                              value={
                                values?.[original_name]
                                  ? {
                                      label: values?.[original_name],
                                      value: values?.[original_name],
                                    }
                                  : null
                              }
                              onChange={(option: any) => {
                                setFieldValue(original_name, option.value);
                              }}
                            />
                          ) : display_field_type === "boolean" ? (
                            <div className="form-switch form-check-md">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={values?.[original_name]}
                                name={original_name}
                                onBlur={handleBlur}
                                onChange={(event: any) => {
                                  setFieldValue(
                                    original_name,
                                    event.target.checked
                                  );
                                }}
                              />
                            </div>
                          ) : display_field_type === "text" ? (
                            <textarea
                              name={original_name}
                              className="form-control"
                              value={values?.[original_name]}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          ) : display_field_type === "datetime" ||
                            display_field_type === "date" ? (
                            <div className="icon-form">
                              <span className="form-icon">
                                <i className="ti ti-calendar-check" />
                              </span>
                              <DatePicker
                                showTimeSelect={
                                  display_field_type === "datetime"
                                }
                                className="form-control datetimepicker deals-details w-100"
                                selected={getDateValue(values?.[original_name])}
                                onChange={(date: any) => {
                                  setFieldValue(original_name, date);
                                }}
                                dateFormat={
                                  display_field_type === "datetime"
                                    ? "dd-MM-yyyy h:mm aa"
                                    : "dd-MM-yyyy"
                                }
                                readOnly={
                                  original_name === "created_at" ||
                                  original_name === "updated_at"
                                }
                                popperClassName="datepicker-popper"
                              />
                            </div>
                          ) : display_field_type === "filters" ? (
                            <button
                              type="button"
                              className="mx-4 btn btn-success fs-5"
                              onClick={() => {
                                dispatch(
                                  setActiveModal({
                                    modalId: "seagments_filters",
                                    modalData: {
                                      service: values?.['service'],
                                      filters: values?.['filters'],
                                    },
                                    onSubmitFinish: (payload: any) => {
                                      setFieldValue(original_name, payload);
                                    },
                                  })
                                );
                              }}
                              disabled={!values?.['service']}
                            >
                              <i className="ti ti-adjustments-horizontal" />
                            </button>
                          ) : (
                            <input
                              type={
                                display_field_type === "char"
                                  ? "text"
                                  : (display_field_type as string)?.includes(
                                      "email"
                                    )
                                  ? "email"
                                  : (display_field_type as string)?.includes(
                                      "phone"
                                    )
                                  ? "tel"
                                  : "number"
                              }
                              name={original_name}
                              className="form-control"
                              value={values?.[original_name]}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                        {touched?.[original_name] &&
                          errors?.[original_name] && (
                            <Alert
                              message={errors?.[original_name] as string}
                              type="error"
                            />
                          )}
                        {/* {display_field_type === "filters" &&
                        values?.["filters"]?.length ? (
                          <div className="filters-table mb-4">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Field</th>
                                  <th>Operator</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {values?.["filters"]?.map((filterItem: any) => (
                                  <tr key={filterItem?.['attribute']}>
                                    <td>{filterItem?.['attribute']}</td>
                                    <td>{filterItem?.['operator']}</td>
                                    <td>{filterItem?.['value']}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null} */}
                      </div>
                    )
                  )}
                </div>
                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      {errors.submit && (
                        <Alert message={errors.submit as string} type="error" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => {
                      dispatch(setEditingSeagmentClose());
                      dispatch(closeModal("add_seagment"));
                    }}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData?.seagment_id === "add" ? "Create" : "Edit"}
                  </button>

                  <button 
                    type="button" 
                    className="btn btn-primary"
                    disabled={!values.service}
                    onClick={() => {
                      dispatch(
                        setActiveModal({
                          modalId: "seagment_preview",
                          modalData: {
                            service: values.service,
                            filters: values.filters,
                            name: values.name
                          }
                        })
                      );
                    }}
                  >
                    Preview
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddSeagmentModal;
