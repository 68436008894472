import { createSlice } from "@reduxjs/toolkit";
import {
  createSeagment,
  editSeagment,
  getSeagment,
  getSeagments,
  deleteSeagment,
  filterSortSeagments,
} from "./thunk";
import { sortBy } from "lodash";
import { TablePaginationConfig } from "antd";

const initialState: {
  seagments: {
    loading: boolean;
    list: any[];
    pagination: TablePaginationConfig;
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    view: {
      data: any;
      loading: boolean;
    };
    select: {
      isOpen: boolean;
      data: any;
    };
  };
} = {
  seagments: {
    loading: false,
    list: [],
    edit: {
      data: null,
      loading: false,
    },
    pagination: {
      total: 0,
      current: 1,
    },
    delete: {
      data: null,
    },
    view: {
      data: null,
      loading: false,
    },
    select: {
      isOpen: false,
      data: null
    },
  },
};

export const seagmentSlice = createSlice({
  name: "seagment",
  initialState,
  reducers: {
    // Seagment Actions
    setEditingSeagment: (state, action) => {
      state.seagments = {
        ...(state.seagments || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingSeagmentClose: (state) => {
      state.seagments = {
        ...(state.seagments || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveSeagment: (state, action) => {
      state.seagments = {
        ...(state.seagments || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveSeagmentClose: (state) => {
      state.seagments = {
        ...(state.seagments || {}),
        delete: {
          data: null,
        },
      };
    },
    setSeagmentView: (state, action) => {
      state.seagments = {
        ...(state.seagments || {}),
        view: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setSeagmentViewClose: (state) => {
      state.seagments = {
        ...(state.seagments || {}),
        view: {
          data: null,
          loading: false,
        },
      };
    },
    setSelectSeagment: (state, action) => {
      state.seagments = {
        ...(state.seagments || {}),
        select: {
          isOpen: true,
          data: action.payload,
        },
      };
    },
    setSelectSeagmentClose: (state) => {
      state.seagments = {
        ...(state.seagments || {}),
        select: {
          isOpen: false,
          data: null
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Seagment Actions
      .addCase(createSeagment.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createSeagment.fulfilled, (state, action) => {
        const seagmentData: any = action.payload;
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: [...state.seagments.list, seagmentData],
        };
      })
      .addCase(createSeagment.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(editSeagment.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            ...(state.seagments.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editSeagment.fulfilled, (state, action) => {
        const seagmentData: any = action.payload;
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: state.seagments.list?.map((item) => 
            item.seagment_id === seagmentData.seagment_id ? seagmentData : item
          ),
        };
      })
      .addCase(editSeagment.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getSeagment.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          view: {
            ...(state.seagments.view || {}),
            loading: state.seagments.view?.loading,
          },
          loading: true,
        };
      })
      .addCase(getSeagment.fulfilled, (state, action) => {
        const seagmentData = action.payload;
        const existingData = (state.seagments.list || []).map((item: any) => 
          item.seagment_id === seagmentData?.seagment_id ? seagmentData : item
        );
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
          view: {
            data: {
              ...(state.seagments.view?.data || {}),
              visitingSeagmentData: { ...(action.payload || {}) }
            },
            loading: state.seagments.view?.loading,
          },
          list: [...existingData],
        };
      })
      .addCase(getSeagment.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          view: {
            data: null,
            loading: state.seagments.view?.loading,
          },
          loading: false,
        };
      })
      .addCase(getSeagments.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: true,
          pagination: {
            total: 0,
            current: 1,
          },
        };
      })
      .addCase(getSeagments.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: 1,
          },
        };
      })
      .addCase(getSeagments.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
        };
      })
      .addCase(filterSortSeagments.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: true,
        };
      })
      .addCase(filterSortSeagments.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(filterSortSeagments.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
        };
      })
      .addCase(deleteSeagment.pending, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: true,
        };
      })
      .addCase(deleteSeagment.fulfilled, (state, action) => {
        const seagmentId = action.payload;
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
          list: (state.seagments.list || []).filter(
            (item: any) => item.seagment_id !== seagmentId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteSeagment.rejected, (state, action) => {
        state.seagments = {
          ...(state.seagments || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingSeagment,
  setEditingSeagmentClose,
  setRemoveSeagment,
  setRemoveSeagmentClose,
  setSeagmentView,
  setSeagmentViewClose,
  setSelectSeagment,
  setSelectSeagmentClose
} = seagmentSlice.actions;

export default seagmentSlice.reducer;
