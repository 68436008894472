import { Navigate, Route } from "react-router";
import { all_routes } from "./all_routes";
import BlankPage from "../pages/blankPage";
import WelcomePage from "../pages/welcomePage";
import ComingSoon from "../pages/comingSoon";
import LockScreen from "../authentication/lockscreen";
import Login from "../auth/login";
import Register from "../auth/register";
import TwoStepVerification from "../auth/twoStepVerification";
import EmailVerification from "../auth/emailVerification";
import Success from "../auth/success";
import ResetPassword from "../auth/resetPassword";
import ForgotPassword from "../auth/forgotPassword";
import Error404 from "../pages/error/error-404";
import Error500 from "../pages/error/error-500";
import Notifications from "../settings/generalSettings/notifications";
import Profile from "../settings/generalSettings/profile";
import Security from "../settings/generalSettings/security";
import UnderMaintenance from "../pages/underMaintenance";

import MenuGroups from "../crm/groups/groupList";
import MenuGroupDetail from "../crm/groups/groupDetail";
import MenuDetail from "../crm/menus/menuDetail";
import AppConnectivity from "../crm/connectivity";
import ManageUsers from "../crm/users";
import ManageUserGroups from "../crm/userGroups";
import AdminContacts from "../crm/admin/adminContacts";
import AdminLead from "../crm/admin/adminLead";
import AdminClosedLead from "../crm/admin/adminClosedLead";

import ManageContacts from "../crm/contacts/manageContacts";
import ManageLeads from "../crm/leads/manageLeads";

import LeadDetail from "../crm/leads/leadDetail";

import ManageCampaigns from "../crm/campaign/campaignList";
import CampaignLeads from "../crm/campaign/campaignLeads";
import AdminCampaign from "../crm/admin/adminCampaign";
import ErrorNoPermission from "../pages/error/error-no-permission";
import ManageCompanies from "../crm/company/manageCompanies";
import AdminCompanies from "../crm/admin/adminCompany";
import ManageSeagments from "../crm/seagments/manageSeagments";

const route = all_routes;

export const publicRoutes = [
  {
    path: route.blankPage,
    element: <BlankPage />,
    route: Route,
  },
  {
    path: route.groups,
    element: <MenuGroups />,
    route: Route,
  },
  {
    path: route.groupDetail,
    element: <MenuGroupDetail />,
    route: Route,
  },
  {
    path: route.menuDetail,
    element: <MenuDetail />,
    route: Route,
  },
  {
    path: route.users,
    element: <ManageUsers />,
    route: Route,
  },
  {
    path: route.userGroups,
    element: <ManageUserGroups />,
    route: Route,
  },
  {
    path: route.appConnectivity,
    element: <AppConnectivity />,
    route: Route,
  },
  {
    path: route.contacts,
    element: <ManageContacts />,
    route: Route,
  },

  {
    path: route.leads,
    element: <ManageLeads />,
    route: Route,
  },

  {
    path: route.leadDetail,
    element: <LeadDetail />,
    route: Route,
  },

  {
    path: route.companies,
    element: <ManageCompanies />,
    route: Route,
  },

  {
    path: route.campaigns,
    element: <ManageCampaigns />,
    route: Route,
  },

  {
    path: route.campaignLeads,
    element: <CampaignLeads />,
    route: Route,
  },

  {
    path: route.campaignAdmin,
    element: <AdminCampaign />,
    route: Route,
  },

  // Admin Routes
  {
    path: route.adminContact,
    element: <AdminContacts />,
    route: Route,
  },
  {
    path: route.adminLead,
    element: <AdminLead />,
    route: Route,
  },
  {
    path: route.adminClosedLead,
    element: <AdminClosedLead />,
    route: Route,
  },

  {
    path: route.adminCompany,
    element: <AdminCompanies />,
    route: Route,
  },

  // Admin Routes
  {
    path: route.adminSeagment,
    element: <ManageSeagments />,
    route: Route,
  },

  {
    path: route.welcomePage,
    element: <WelcomePage />,
    route: Route,
  },
  {
    path: route.notification,
    element: <Notifications />,
    route: Route,
  },
  {
    path: route.profile,
    element: <Profile />,
    route: Route,
  },
  {
    path: route.security,
    element: <Security />,
    route: Route,
  },
  {
    path: route.error404,
    element: <Error404 />,
    route: Route,
  },
  {
    path: route.errorNoPermission,
    element: <ErrorNoPermission />,
    route: Route,
  },
];

export const authRoutes = [
  {
    path: route.comingSoon,
    element: <ComingSoon />,
    route: Route,
  },
  {
    path: route.login,
    element: <Login />,
    route: Route,
  },
  {
    path: route.register,
    element: <Register />,
    route: Route,
  },
  {
    path: route.twoStepVerification,
    element: <TwoStepVerification />,
    route: Route,
  },
  {
    path: route.emailVerification,
    element: <EmailVerification />,
    route: Route,
  },
  {
    path: route.success,
    element: <Success />,
    route: Route,
  },
  {
    path: route.register,
    element: <Register />,
    route: Route,
  },
  {
    path: route.resetPassword,
    element: <ResetPassword />,
    route: Route,
  },
  {
    path: route.forgotPassword,
    element: <ForgotPassword />,
    route: Route,
  },
  {
    path: route.error404,
    element: <Error404 />,
    route: Route,
  },
  {
    path: route.error500,
    element: <Error500 />,
    route: Route,
  },
  {
    path: route.underMaintenance,
    element: <UnderMaintenance />,
    route: Route,
  },
  {
    path: route.lockScreen,
    element: <LockScreen />,
  },
];
