import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";

const AuthFeature = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: any) => !!state.auth.currentUser?.token
  );
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/welcome");
    } else {
      setIsAuthenticating(false); // Ensure state updates properly when unauthenticated
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticating) return null;

  return <Outlet />;
};

export default AuthFeature;
