import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  activeModals: Record<
    string,
    {
      order: number;
      onSubmitFinish?: (payload: any) => void;
      modalData: any
    }
  >;
  currentOrder: number;
}

const initialState: ModalState = {
  activeModals: {},
  currentOrder: 0,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setActiveModal: (
      state,
      action: PayloadAction<{
        modalId: string;
        onSubmitFinish?: (payload: any) => void;
        modalData?: any;
      }>
    ) => {
      state.currentOrder = (state.currentOrder ?? 0) + 1;
      state.activeModals = {
        ...(state.activeModals || {}),
        [action.payload.modalId]: {
          order: state.currentOrder,
          onSubmitFinish: action.payload?.onSubmitFinish,
          modalData: action.payload?.modalData
        },
      };
    },
    closeModal: (state, action: PayloadAction<string>) => {
      delete state.activeModals[action.payload];
    },
  },
});

export const { setActiveModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
